import {useEffect, useState} from "react";

const useDayInMonth = ({year, month}: {year: number; month: number}) => {
    const [days, setDays] = useState<number[]>([]);
    const [prevDow, setPrevDow] = useState<number>(0);
    const [nextDow, setNextDow] = useState<number>(0);

    useEffect(() => {
        const daysInMonth = new Date(year, month, 0).getDate();
        const daysArray = [];
        for (let i = 1; i <= daysInMonth; i++) {
            daysArray.push(i);
        }
        setDays(daysArray);
        setPrevDow(new Date(year, month - 1, 1).getDay());
        setNextDow(new Date(year, month - 1, daysArray.at(-1)).getDay());
    }, [year, month]);

    return {days, prevDow, nextDow};
};

export {useDayInMonth};
