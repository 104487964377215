import {useMemo, useState} from "react";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import {httpRequest} from "~/fetch/common/http-request";

import {
    CostMaintenanceLightingModalRequest,
    CostMaintenanceLightingModalResponse,
    CostMaintenanceLightingModalUpdateRequest,
    CostMaintenanceModalData,
    CostMaintenanceModalUpdate,
    CostRepairApplyDTOListType,
} from "./maintenance.modal.interface";

const useCostMaintenanceModalData = (
    config: CostMaintenanceModalData,
): [(lastMonth?: boolean) => Promise<void>, CostMaintenanceLightingModalResponse | null, boolean] => {
    const [response, setResponse] = useState<CostMaintenanceLightingModalResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        const refinedData = () => {
            if (config.selected.length) {
                switch (config.selected[0]) {
                    case "ALL":
                        return null;
                    // break;
                    case "SECTOR":
                        return {
                            sectorId: config.selected[1],
                            ...(config.selected[2] !== "0" && {buildingId: config.selected[2]}),
                        };
                    // break;
                    case "TRAINER":
                        return {
                            trainerSectorId: config.selected[1],
                            ...(config.selected[2] !== "0" && {buildingId: config.selected[2]}),
                        };
                    // break;
                    default:
                        return null;
                    // break;
                }
            }
        };

        return (lastMonth?: boolean) => {
            const {
                year = dayjs(new Date()).format("YYYY"),
                month = dayjs(new Date()).format("MM"),
                recordPerPage = 20,
                pageNo = 1,
            } = {
                year:
                    lastMonth && config.date.month.month() === 0
                        ? config.date.year.subtract(1, "year").format("YYYY")
                        : config.date.year.format("YYYY"),
                month: lastMonth ? config.date.month.subtract(1, "month").format("MM") : config.date.month.format("MM"),
                recordPerPage: config.perPage,
                pageNo: config.page,
            };

            return httpRequest<CostMaintenanceLightingModalRequest, CostMaintenanceLightingModalResponse>({
                method: "GET",
                url: "/lotte/cost/repair/total/list",
                params: {
                    year,
                    month,
                    ...refinedData(),
                    recordPerPage,
                    pageNo,
                },
            });
        };
    }, [config]);

    const submit = async (lastMonth?: boolean) => {
        try {
            setIsLoading(true);
            const res = await request(lastMonth).submit();

            setResponse(res.data);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading];
};
const useCostMaintenanceModalUpdate = (config: CostMaintenanceModalUpdate): [() => Promise<void>, boolean] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        const {
            costRepairId = 0,
            year = dayjs(new Date()).format("YYYY"),
            month = dayjs(new Date()).format("MM"),
            targetCost = 0,
            userId = null,
            tableData = [],
        } = {
            costRepairId: config.costRepairId,
            year: config.date.year.format("YYYY"),
            month: config.date.month.format("MM"),
            targetCost: config.targetCost,
            userId: config.userId,
            tableData: config.tableData,
        };

        return httpRequest<CostMaintenanceLightingModalUpdateRequest, null>({
            method: "POST",
            url: "/lotte/cost/repair/total/reg",
            data: {
                costRepairId,
                year,
                month,
                costRepairGoal: targetCost ?? 0,
                updateUser: userId,
                //? createUser: config.createUser
                costRepairApplyDTOList: tableData,
            },
        });
    }, [config]);

    const submit = async () => {
        Swal.fire({
            title: "수선비 등록",
            text: "저장하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "저장하기",
            cancelButtonText: "취소하기",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setIsLoading(true);
                    await request.submit();

                    Swal.fire({
                        text: "저장되었습니다",
                        confirmButtonText: "확인",
                    });
                } catch (error) {
                    console.log(error);
                    Swal.fire({
                        text: "오류가 발생하였습니다. 다시 시도해주세요",
                        confirmButtonText: "확인",
                    });
                } finally {
                    setIsLoading(false);
                }
                config.close();
            }
        });
    };

    return [submit, isLoading];
};

// 지점 수선비 수정
const useCostMaintenanceModalUpdateManager = (
    config: CostRepairApplyDTOListType[],
    close: () => void,
): [() => Promise<void>, boolean] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<CostRepairApplyDTOListType | null, null>({
            method: "PUT",
            url: "/lotte/cost/repair/apply/upd",
            data: config.length ? config[0] : null,
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            await request.submit();

            Swal.fire({
                text: "저장되었습니다",
                confirmButtonText: "확인",
            });
        } catch (error) {
            console.log(error);
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
        } finally {
            setIsLoading(false);
            close();
        }
    };

    return [submit, isLoading];
};

export {useCostMaintenanceModalData, useCostMaintenanceModalUpdate, useCostMaintenanceModalUpdateManager};
