import React, {ChangeEvent, useEffect, useState} from "react";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as ButtonAntd, DatePicker, Input, InputNumber, Radio, Select, Upload} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import classNames from "classnames/bind";

import Button from "~/components/button/button";
import {PageTitle} from "~/components/page-title/page-title";
import {
    useEquipmentCheckDTOList,
    useEquipmentRegisterTargetList,
    useEquipmentRegistration,
} from "~/data/machine/register/use-register";
import {singleFileUpload} from "~/fetch/single-file-upload";
import {fileToBase64} from "~/utills/file-to-base64";

import styles from "./equipment-board-register.modal.module.scss";
import stylesGrid from "~/components/merge/merge-grid-item/merge-grid-item.module.scss";

import "dayjs/locale/ko";

const cx = classNames.bind(styles);
const gridCx = classNames.bind(stylesGrid);

// EquipmentBoardRegisterModalProps
const EquipmentBoardRegisterModal = ({onClose, defaultData}: any) => {
    const [isInitialize, setIsInitialize] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [searchSpecific, setSearchSpecific] = useState<string>("");
    // const [files, setFiles] = useState({});

    // <EquipmentRegisterInput>
    const [input, setInput] = useState<any>({
        machineName: "", // 장비명 (필)
        machineCategoryCode: "", // 카테고리 (필)
        machineManufactureCompany: "", // 제조사
        machineRegionId: 0, //구역
        machineInstallDate: "", // 설치연월
        machineManufactureDate: "", // 제조연월
        machineManufactureNumber: "", // 제조번호
        machineModelName: "", // 모델명
        machineInstallCompany: "", // 설치업체
        machineLocalCode: "", // 장비코드
        machineUseYn: "N", // 사용여부
        machineQuantitiy: 0, // 보유수량
        machineNote: "", // 비고
        machineImageUrl: "", // 이미지 경로
        // 추가정보 배열 (필)
        machineCheckDTOList: [],
        // machineCheckDTOList: [
        //     {
        //         machineCheckId: "", // 추가정보 아이디 (필)
        //         machineCategoryCode: "", // 카테고리 코드 (필)
        //         machineCheckCode: "", // 추가정보 코드 (필)
        //         machineCheckName: "", // 추가정보명 string - 왼쪽 (필)
        //         machineCheckAnswer: "", // 추가정보 답변 string - 오른쪽 (필)
        //     },
        // ],
    });

    // 추가정보 02 (카테고리 선택 시 해당 추가정보란 불러옴)
    const [submitCheckList, checkList] = useEquipmentCheckDTOList(input);

    // 셀렉트들 (카테고리, 제조사, 구역, 설치업체)
    const [submitTargetList, targetList] = useEquipmentRegisterTargetList(defaultData);

    // 등록
    const [submitRegister] = useEquipmentRegistration(input, setIsLoading);

    // 초기 셋팅
    useEffect(() => {
        setIsLoading(true);
        setIsInitialize(true);
        submitTargetList();
    }, []);

    // 추가정보 01
    useEffect(() => {
        submitCheckList();
    }, [input.machineCategoryCode]);

    // 닫기
    useEffect(() => {
        if (!isLoading && isInitialize) setTimeout(() => onClose(), 500);
    }, [isLoading]);

    useEffect(() => {
        const handleCloseESC = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsLoading(false);
            }
        };

        if (isLoading) {
            document.addEventListener("keydown", handleCloseESC);
        }
        return () => {
            document.removeEventListener("keydown", handleCloseESC);
        };
    }, [isLoading]);

    // 추가정보 03
    useEffect(() => {
        if (checkList && checkList.length > 0) {
            setInput({
                ...input,
                machineCheckDTOList: checkList.map((el: any) => ({
                    machineCheckId: null,
                    machineCategoryCode: el.machineCategoryCode,
                    machineCheckCode: el.machineCheckCode,
                    machineCheckName: el.machineCheckName,
                    machineCheckAnswer: "",
                })),
            });
        }
    }, [checkList]);

    // 닫기
    const handleClose = () => {
        setIsLoading(false);
    };

    // 등록
    const handleRegistration = async () => {
        await submitRegister();
    };

    // 장비명, 제조번호, 모델명, 장비코드, 비고
    const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value.replace(/\s+/g, " ").trim(),
        });
    };

    // 보유수량
    const handleChangeInputNumber = (value: any) => {
        setInput({
            ...input,
            machineQuantitiy: value,
        });
    };

    // 추가정보 04 기입
    const handleInputChange = (index: any, value: any) => {
        const newCDataList: any = [...input.machineCheckDTOList]; // 기존의 상태 복사
        newCDataList[index].machineCheckAnswer = value; // 특정 인덱스의 값 업데이트
        setInput({...input, machineCheckDTOList: newCDataList}); // 상태 업데이트
    };

    const [imageFile, setImageFile] = useState<{
        file: string;
        fileName: string;
        filePath: string;
    }>();

    return (
        <div className={cx("container", {active: isLoading})}>
            <div
                className={cx("inner", {active: isLoading}, "large")}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <div className={cx("contents-container")}>
                    <PageTitle>설비현황등록</PageTitle>
                    <div className={cx("info")}>
                        <h3>
                            기본정보{" "}
                            <span style={{color: "#d9534f", marginLeft: "8px", fontSize: "14px"}}>* 필수항목</span>
                        </h3>
                        <table className={cx("table")}>
                            <colgroup>
                                <col width="40%" />
                                <col width="15%" />
                                <col width="15%" />
                                <col width="15%" />
                                <col width="15%" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td>
                                        <Upload
                                            className={cx("field")}
                                            maxCount={1}
                                            showUploadList={false}
                                            customRequest={singleFileUpload}
                                            onChange={(res) => {
                                                if (res.fileList.length === 0) setImageFile(undefined);

                                                try {
                                                    if (res.file.status === "done") {
                                                        fileToBase64(res.file.originFileObj).then((rst: string) => {
                                                            setImageFile({
                                                                file: rst,
                                                                fileName: res.file.fileName as string,
                                                                filePath: res.file.response.filePath,
                                                            });
                                                            setInput({
                                                                ...input,
                                                                machineImageUrl: res.file.response.filePath,
                                                            });
                                                        });
                                                    }
                                                } catch (e) {
                                                    console.error(e);
                                                }
                                            }}
                                        >
                                            <ButtonAntd icon={<FontAwesomeIcon icon={faUpload} />}>
                                                이미지 업로드
                                            </ButtonAntd>
                                        </Upload>
                                    </td>
                                    <th>지점명</th>
                                    <td>{defaultData.buildingName}</td>
                                    <th>* 장비명</th>
                                    <td>
                                        <Input
                                            className={gridCx("input")}
                                            name="machineName"
                                            onChange={handleChangeInput}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={10} className={cx("image-cell")}>
                                        <img style={{width: "100%"}} src={imageFile?.file.toString()} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>* 카테고리</th>
                                    <td colSpan={3}>
                                        <Select
                                            showSearch
                                            placeholder="선택"
                                            style={{width: "100%"}}
                                            onChange={(value) => {
                                                setInput({
                                                    ...input,
                                                    machineCategoryCode: value,
                                                });
                                            }}
                                            options={targetList?.category as {value: string; label: string}[]}
                                            filterOption={(input, option) => {
                                                if (option) {
                                                    return option.label.toLowerCase().includes(input.toLowerCase());
                                                }
                                                return false;
                                            }}
                                            getPopupContainer={(trigger) => trigger.parentElement}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>제조사</th>
                                    <td>
                                        <Select
                                            showSearch
                                            placeholder="선택"
                                            style={{width: "100%"}}
                                            onChange={(value) => {
                                                setInput({
                                                    ...input,
                                                    machineManufactureCompany: value,
                                                });
                                            }}
                                            options={targetList?.manufactureCompany as {value: string; label: string}[]}
                                            filterOption={(input, option) => {
                                                if (option) {
                                                    return option.label.toLowerCase().includes(input.toLowerCase());
                                                }
                                                return false;
                                            }}
                                            getPopupContainer={(trigger) => trigger.parentElement}
                                        />
                                    </td>
                                    <th>구역</th>
                                    <td>
                                        <Select
                                            showSearch
                                            placeholder="선택"
                                            style={{width: "100%"}}
                                            onChange={(value) => {
                                                setInput({
                                                    ...input,
                                                    machineRegionId: value,
                                                });
                                            }}
                                            options={targetList?.region as {value: string; label: string}[]}
                                            filterOption={(input, option) => {
                                                if (option) {
                                                    return option.label.toLowerCase().includes(input.toLowerCase());
                                                }
                                                return false;
                                            }}
                                            getPopupContainer={(trigger) => trigger.parentElement}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>설치연월</th>
                                    <td>
                                        <DatePicker
                                            locale={locale}
                                            picker="month"
                                            onChange={(date, dateString: any) => {
                                                setInput({
                                                    ...input,
                                                    machineInstallDate: dateString.replace("-", ""),
                                                });
                                            }}
                                            placeholder="년/월 선택"
                                            style={{width: "100%"}}
                                            format="YYYY-MM"
                                        />
                                    </td>
                                    <th>제조연월</th>
                                    <td>
                                        <DatePicker
                                            picker="month"
                                            onChange={(date, dateString: any) => {
                                                setInput({
                                                    ...input,
                                                    machineManufactureDate: dateString.replace("-", ""),
                                                });
                                            }}
                                            placeholder="년/월 선택"
                                            style={{width: "100%"}}
                                            format="YYYY-MM"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>제조번호</th>
                                    <td colSpan={3}>
                                        <Input
                                            className={gridCx("input")}
                                            name="machineManufactureNumber"
                                            onChange={handleChangeInput}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>모델명</th>
                                    <td colSpan={3}>
                                        <Input
                                            className={gridCx("input")}
                                            name="machineModelName"
                                            onChange={handleChangeInput}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>설치업체</th>
                                    <td colSpan={3}>
                                        <Select
                                            showSearch
                                            placeholder="선택"
                                            style={{width: "100%"}}
                                            onChange={(value) => {
                                                setInput({
                                                    ...input,
                                                    machineInstallCompany: value,
                                                });
                                            }}
                                            filterOption={(input, option) => {
                                                if (option) {
                                                    return option.label.toLowerCase().includes(input.toLowerCase());
                                                }
                                                return false;
                                            }}
                                            options={targetList?.installCompany as {value: string; label: string}[]}
                                            getPopupContainer={(trigger) => trigger.parentElement}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>장비코드</th>
                                    <td colSpan={3}>
                                        <Input
                                            className={gridCx("input")}
                                            name="machineLocalCode"
                                            onChange={handleChangeInput}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>사용여부</th>
                                    <td>
                                        <Radio.Group
                                            onChange={(e) => {
                                                setInput({
                                                    ...input,
                                                    machineUseYn: e.target.value,
                                                });
                                            }}
                                            defaultValue={"N"}
                                        >
                                            <Radio value={"Y"}>사용</Radio>
                                            <Radio value={"N"}>미사용</Radio>
                                        </Radio.Group>
                                    </td>
                                    <th>보유수량</th>
                                    <td>
                                        <InputNumber
                                            className={gridCx("input")}
                                            name="machineQuantitiy"
                                            onChange={handleChangeInputNumber}
                                            min={0}
                                            defaultValue={0}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>비고</th>
                                    <td colSpan={3}>
                                        <Input
                                            className={gridCx("input")}
                                            name="machineNote"
                                            onChange={handleChangeInput}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {checkList && checkList.length !== 0 && (
                        <div className={cx("info")}>
                            <h3>추가정보</h3>
                            <table className={cx("table")}>
                                <colgroup>
                                    <col width="30%" />
                                    <col width="70%" />
                                </colgroup>
                                <tbody>
                                    {checkList.map(
                                        (
                                            el: {
                                                machineCategoryCode: string; // 카테고리 코드 (필)
                                                machineCheckCode: string; // 추가정보 코드 (필)
                                                machineCheckName: string; // 추가정보명 string - 왼쪽 (필)
                                            },
                                            index: number,
                                        ) => (
                                            <tr key={index}>
                                                <th>* {el.machineCheckName}</th>
                                                <td>
                                                    <Input
                                                        className={gridCx("input")}
                                                        name={el.machineCheckName}
                                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                                        value={
                                                            input.machineCheckDTOList[index]?.machineCheckAnswer || ""
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        ),
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>

                <div className={cx("button-container")}>
                    <Button onClick={handleRegistration} label="등록" />
                    <Button onClick={handleClose} label="닫기" color={"primary"} />
                </div>
            </div>
        </div>
    );
};

export {EquipmentBoardRegisterModal};
