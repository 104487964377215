import {ChangeEvent, useEffect, useState} from "react";
import {faDownload, faPaperclip, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as ButtonAntd, DatePicker, Input, InputNumber, List, Radio, Select, Spin, Upload} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {MergeGridItem} from "~/components/merge/merge-grid-item/merge-grid-item";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title/page-title";
import {useCostFilterTypeList} from "~/data/cost/common/use-cost-filter";
import {useFacilityList} from "~/data/cost/common/use-facility-list";
import {
    useCostFacilityDelete,
    useCostFacilityRegistration,
} from "~/data/cost/facility-maintenance/modal/use-cost-facility-cud";
import {useCostFacilityData} from "~/data/cost/facility-maintenance/modal/use-cost-facility-data";
import {
    useCostFacilityMemberConfirm,
    useCostFacilityMemberFix,
} from "~/data/cost/facility-maintenance/modal/use-cost-facility-member";
import {
    useCostFacilityReportDelete,
    useCostFacilityReportDownload,
} from "~/data/cost/facility-maintenance/modal/use-cost-facility-report";
import {useUser} from "~/data/user/use-user";
import {formatCost} from "~/utills/formatCost";

import {
    CostFacilityMaintenanceModalInput,
    CostFacilityMaintenanceModalProps,
} from "./cost-facility-maintenance.modal.interface";

import styles from "./cost-facility-maintenance.modal.module.scss";
import stylesGrid from "~/components/merge/merge-grid-item/merge-grid-item.module.scss";

const cx = classNames.bind(styles);
const gridCx = classNames.bind(stylesGrid);

const CostFacilityMaintenanceModal = ({
    costFacilityId,
    close,
    division,
    submitTableList,
    buildingId,
}: CostFacilityMaintenanceModalProps) => {
    const [inputs, setInputs] = useState<CostFacilityMaintenanceModalInput>({
        year: dayjs(new Date()),
        month: dayjs(new Date()),
        typeId: null, // 유형
        yearProcessId: null, // 공정
        yearConstructName: "", // 공사건명
        yearMachineLinkYn: "Y", // 설비연동
        monthConstructGoYn: "Y", // 선진행 공사여부
        yearMachineId: [], // 설비
        monthConstructDate: null, // 공사예가 제출일
        monthConstructGoReason: "", // 공사사유
        monthConstructCompany: "", // 공사예가 제출업체
        monthConstructCost: 0, //금액
        storeContributionAmount: 0, // 마트 부담금액
        offsetAmount: 0, // 상계처리비용
        monthEstimateFileObj: null, // 견적서
        monthMemo: "", // 비고
    });
    const [fileName, setFileName] = useState<string | null>(null);
    const [fixedCost, setFixedCost] = useState<number | null>(null);
    const [machineCategory, setMachineCategory] = useState<string>("ALL");

    const {user} = useUser();

    // 상세정보
    const [submitData, data, isDataLoading] = useCostFacilityData(costFacilityId);
    // 설비 리스트
    const [submitFacilityList, facilityList, getMachineCategory, machineCategoryRes] = useFacilityList(
        buildingId === 0 ? user?.buildingId ?? 0 : buildingId,
        machineCategory,
    );
    // 유형, 공정
    const [submitTypeList, options] = useCostFilterTypeList();
    // 등록
    const [submitRegistration] = useCostFacilityRegistration({
        ...inputs,
        costFacilityId,
        buildingId,
    });
    // 삭제
    const [submitDelete] = useCostFacilityDelete({costFacilityId, division});
    // 견적서 다운로드
    const [downloadReport] = useCostFacilityReportDownload(costFacilityId);
    // 견적서 삭제
    const [deleteReport] = useCostFacilityReportDelete(costFacilityId);
    // 본사 확인 & 반려
    const [checkMember, reject] = useCostFacilityMemberConfirm({costFacilityId});
    // 본사 확정
    const [fixMember] = useCostFacilityMemberFix({costFacilityId, fixedCost});

    useEffect(() => {
        submitTypeList();
        if (costFacilityId !== 0) submitData();
    }, []);

    useEffect(() => {
        submitFacilityList();
        getMachineCategory();
    }, [user]);
    // 지점 수정 시 값 셋팅
    useEffect(() => {
        if (data) {
            setInputs({
                year: dayjs().year(Number(data.year)),
                month: dayjs().month(Number(data.month) - 1),
                typeId: data.typeId,
                yearProcessId: data.yearProcessId,
                yearConstructName: data.yearConstructName,
                yearMachineLinkYn: data.yearMachineLinkYn ?? "N",
                yearMachineId:
                    data.yearMachineId === "0" ? [] : data.yearMachineId.split(",").map((data) => Number(data)),
                monthConstructDate: dayjs(new Date(data.monthConstructDate ?? "")),
                monthConstructCompany: data.monthConstructCompany ?? "",
                monthConstructCost: data.monthConstructCost,
                monthConstructGoReason: data.monthConstructGoReason ?? "",
                monthConstructGoYn: data.monthConstructGoYn ?? "N",
                monthEstimateFileObj: null,
                monthMemo: data.monthMemo,
                offsetAmount: data.offsetAmount,
                storeContributionAmount: data.storeContributionAmount,
            });
            setFileName(data.monthEstimateFile);
            setFixedCost(data.monthConstructCost);
        }
    }, [data]);

    useEffect(() => {
        if (buildingId !== 0) submitFacilityList();
    }, [buildingId]);

    useEffect(() => {
        submitFacilityList();
    }, [machineCategory]);

    useEffect(() => {
        setFileName(inputs.monthEstimateFileObj?.name ?? null);
    }, [inputs.monthEstimateFileObj]);

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setInputs((prev) => ({...prev, [e.target.name]: e.target.value}));
    };

    // 저장
    const handleConfirm = async () => {
        if (division === "MEMBER") {
            if (data?.state === "M_SUBMIT") {
                await checkMember();
                setTimeout(submitTableList, 500);
                close();
            } else if (data?.state === "M_VERIFY") {
                if (fixedCost) {
                    await fixMember();
                    setTimeout(submitTableList, 500);
                    close();
                } else {
                    Swal.fire({
                        text: "필수 입력사항을 확인해주세요.",
                        confirmButtonText: "확인",
                    });
                }
            }
        } else {
            if (
                (inputs.yearMachineLinkYn === "N" || inputs.yearMachineId.length) &&
                inputs.typeId &&
                inputs.yearProcessId &&
                inputs.yearConstructName &&
                inputs.monthConstructDate &&
                inputs.monthConstructGoReason &&
                inputs.monthConstructCompany &&
                inputs.monthConstructCost
            ) {
                Swal.fire({
                    text: "저장하시겠습니까?",
                    showCancelButton: true,
                    confirmButtonText: "확인",
                    cancelButtonText: "취소",
                }).then(async (info) => {
                    if (info.isConfirmed) {
                        await submitRegistration();
                        setTimeout(submitTableList, 500);
                        close();
                    }
                });
            } else {
                Swal.fire({
                    text: "필수 입력사항을 확인해주세요.",
                    confirmButtonText: "확인",
                });
            }
        }
    };

    const handleUpdateMember = () => {
        if (
            (inputs.yearMachineLinkYn === "N" || inputs.yearMachineId.length) &&
            inputs.typeId &&
            inputs.yearProcessId &&
            inputs.yearConstructName &&
            inputs.monthConstructDate &&
            inputs.monthConstructGoReason &&
            inputs.monthConstructCompany &&
            inputs.monthConstructCost
        ) {
            Swal.fire({
                text: "저장하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "확인",
                cancelButtonText: "취소",
            }).then(async (info) => {
                if (info.isConfirmed) {
                    await submitRegistration();
                    setTimeout(submitTableList, 500);
                    close();
                }
            });
        } else {
            Swal.fire({
                text: "필수 입력사항을 확인해주세요.",
                confirmButtonText: "확인",
            });
        }
    };
    const handleDelete = () => {
        Swal.fire({
            text: "삭제하시겠습니까?",
            showCancelButton: true,
            cancelButtonText: "취소",
            confirmButtonText: "확인",
        }).then((info) => {
            if (info.isConfirmed) {
                submitDelete();
                setTimeout(submitTableList, 500);
                close();
            }
        });
    };
    const handleReject = async () => {
        const {value: rejectReason} = await Swal.fire({
            input: "textarea",
            inputLabel: "반려 사유",
            inputPlaceholder: "반려 사유를 입력해주세요.",
            showCancelButton: true,
            confirmButtonText: "반려하기",
            cancelButtonText: "취소하기",
        });
        if (rejectReason) {
            await reject({rejectReason: rejectReason});
            setTimeout(submitTableList, 500);
            close();
        }
    };

    return (
        <SideModal
            onClose={close}
            confirmLabel={
                data?.rejectStatus === "Y" || data?.state === "M_CONFIRM"
                    ? undefined
                    : division === "MEMBER"
                      ? data?.state === "M_SUBMIT"
                          ? "트레이너 확인"
                          : data?.state === "M_VERIFY"
                            ? "승인"
                            : "확정"
                      : "저장"
            }
            onConfirm={handleConfirm}
            extraButton={
                <>
                    {division === "MEMBER" && data?.state !== "M_CONFIRM" && data?.rejectStatus !== "Y" && (
                        <Button label={"저장"} onClick={handleUpdateMember} />
                    )}
                    {data?.rejectStatus !== "Y" && division === "MEMBER" && (
                        <Button label={"반려"} onClick={handleReject} />
                    )}
                    <Button label={"삭제"} onClick={handleDelete} />
                </>
            }
            nonePrevent
        >
            <PageTitle>월 시행계획 상세</PageTitle>
            {isDataLoading ? (
                <div className={cx("loading")}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <p className={cx("type-reference")}>
                        안전시설공사 : 안전과 연관된 보수 (건축, 전기, 승강기, 기계, 소방, 냉동냉장 外)
                    </p>
                    <p className={cx("type-reference")}>
                        기타유형자산 : 점포 내 환경개선. 고객편의시설, 영업시설 (SIGN, 조명 外)
                    </p>
                    <div className={cx("form-container")}>
                        <div className={cx("detail-content", {member: division === "MEMBER"})}>
                            <MergeGridItem name={"연도"}>
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    data?.state === "M_SUBMIT" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_VERIFY") ? (
                                    <DatePicker
                                        placeholder={"연도 선택"}
                                        picker={"year"}
                                        style={{width: "120px"}}
                                        value={inputs.year}
                                        allowClear={false}
                                        onChange={(e) => setInputs((prev) => ({...prev, year: e}))}
                                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                                    />
                                ) : (
                                    data?.year
                                )}
                            </MergeGridItem>
                            <MergeGridItem name={"월"}>
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    data?.state === "M_SUBMIT" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_VERIFY") ? (
                                    <DatePicker
                                        placeholder={"월 선택"}
                                        popupClassName="no-header"
                                        picker={"month"}
                                        format={"MM"}
                                        style={{width: "120px"}}
                                        value={inputs.month}
                                        onChange={(e) => setInputs((prev) => ({...prev, month: e}))}
                                        allowClear={false}
                                        cellRender={(date) => (
                                            <div className="ant-picker-cell-inner">{dayjs(date).format("MM")}</div>
                                        )}
                                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                                    />
                                ) : (
                                    data?.month
                                )}
                            </MergeGridItem>
                            <MergeGridItem name={"유형"}>
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_SUBMIT" ||
                                    data?.state === "M_VERIFY") ? (
                                    <Select
                                        style={{width: "85%"}}
                                        options={options.typeList}
                                        value={inputs.typeId}
                                        status={inputs.typeId === null ? "error" : ""}
                                        onChange={(e) => setInputs((prev) => ({...prev, typeId: e}))}
                                        placeholder={"유형선택"}
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                    />
                                ) : (
                                    data?.typeName
                                )}
                            </MergeGridItem>
                            <MergeGridItem name={"공정"}>
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_SUBMIT" ||
                                    data?.state === "M_VERIFY") ? (
                                    <Select
                                        style={{width: "85%"}}
                                        options={options.processList}
                                        value={inputs.yearProcessId}
                                        status={inputs.yearProcessId === null ? "error" : ""}
                                        onChange={(e) => setInputs((prev) => ({...prev, yearProcessId: e}))}
                                        placeholder={"공정선택"}
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                    />
                                ) : (
                                    data?.yearProcessName
                                )}
                            </MergeGridItem>
                            {division === "MEMBER" && (
                                <MergeGridItem span name={"지점"}>
                                    {data?.buildingName}
                                </MergeGridItem>
                            )}
                            <MergeGridItem
                                name={"공사건명"}
                                span
                                extraClassName={division === "MEMBER" ? "text-left" : ""}
                            >
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_SUBMIT" ||
                                    data?.state === "M_VERIFY") ? (
                                    <Input
                                        className={cx("input")}
                                        value={inputs.yearConstructName}
                                        status={inputs.yearConstructName === "" ? "error" : ""}
                                        name={"yearConstructName"}
                                        onChange={handleChangeInput}
                                    />
                                ) : (
                                    data?.yearConstructName
                                )}
                            </MergeGridItem>
                            <MergeGridItem name={"설비연동"}>
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_SUBMIT" ||
                                    data?.state === "M_VERIFY") ? (
                                    <Radio.Group
                                        value={inputs.yearMachineLinkYn}
                                        onChange={(e) =>
                                            setInputs((prev) => ({...prev, yearMachineLinkYn: e.target.value}))
                                        }
                                    >
                                        <Radio value={"Y"}>연동</Radio>
                                        <Radio value={"N"}>비연동</Radio>
                                    </Radio.Group>
                                ) : (
                                    data?.yearMachineLinkYnDesc
                                )}
                            </MergeGridItem>
                            <MergeGridItem
                                name={
                                    <div className={cx("two-line-text")}>
                                        <span> 선진행</span>
                                        <br />
                                        <span> 공사 여부</span>
                                    </div>
                                }
                            >
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_SUBMIT" ||
                                    data?.state === "M_VERIFY") ? (
                                    <Radio.Group
                                        value={inputs.monthConstructGoYn}
                                        onChange={(e) =>
                                            setInputs((prev) => ({...prev, monthConstructGoYn: e.target.value}))
                                        }
                                    >
                                        <Radio value={"Y"}>진행</Radio>
                                        <Radio value={"N"}>미진행</Radio>
                                    </Radio.Group>
                                ) : (
                                    data?.monthConstructGoYnDesc
                                )}
                            </MergeGridItem>
                            <MergeGridItem name={"설비 카테고리"} span>
                                <Select
                                    showSearch
                                    style={{width: "95%"}}
                                    placeholder="설비 카테고리 선택"
                                    value={machineCategory}
                                    onChange={(e) => setMachineCategory(e)}
                                    options={machineCategoryRes}
                                    filterOption={(input, option) => {
                                        if (option) {
                                            return option.label.toLowerCase().includes(input.toLowerCase());
                                        }
                                        return false;
                                    }}
                                    disabled={
                                        data?.rejectStatus === "Y" ||
                                        inputs.yearMachineLinkYn === "N" ||
                                        !(
                                            data?.state === "Y_CONFIRM" ||
                                            data?.state === "Y_VERIFY" ||
                                            costFacilityId === 0 ||
                                            data?.state === "M_SUBMIT" ||
                                            data?.state === "M_VERIFY"
                                        )
                                    }
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                />
                            </MergeGridItem>
                            <MergeGridItem name={"설비"} span>
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_SUBMIT" ||
                                    data?.state === "M_VERIFY") ? (
                                    <Select
                                        style={{width: "95%"}}
                                        mode={"multiple"}
                                        placeholder="설비 선택"
                                        disabled={inputs.yearMachineLinkYn === "N"}
                                        status={
                                            inputs.yearMachineId.length
                                                ? ""
                                                : inputs.yearMachineLinkYn === "N"
                                                  ? ""
                                                  : "error"
                                        }
                                        value={inputs.yearMachineId}
                                        onChange={(e) => setInputs((prev) => ({...prev, yearMachineId: e}))}
                                        allowClear
                                        // 검색 기능
                                        filterOption={(input, option) => {
                                            if (option) {
                                                return option.label.toLowerCase().includes(input.toLowerCase());
                                            }
                                            return false;
                                        }}
                                        options={
                                            facilityList?.map((data) => ({
                                                label: data.machineName,
                                                value: data.machineId,
                                            })) ?? []
                                        }
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                    />
                                ) : (
                                    data?.yearMachineName
                                )}
                            </MergeGridItem>
                            {costFacilityId !== 0 && (
                                <MergeGridItem name={"상태"}>
                                    {data?.rejectStatus === "Y" ? "반려" : data?.monthVerifyDesc}
                                </MergeGridItem>
                            )}
                            <MergeGridItem
                                span={costFacilityId === 0}
                                name={
                                    <div className={cx("two-line-text")}>
                                        <span>공사예가</span>
                                        <br />
                                        <span>제출일</span>
                                    </div>
                                }
                            >
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_SUBMIT" ||
                                    data?.state === "M_VERIFY") ? (
                                    <DatePicker
                                        className={cx("datepicker")}
                                        style={{width: costFacilityId === 0 ? "95%" : "80%"}}
                                        status={inputs.monthConstructDate === null ? "error" : ""}
                                        value={inputs.monthConstructDate}
                                        onChange={(e) => setInputs((prev) => ({...prev, monthConstructDate: e}))}
                                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                                    />
                                ) : (
                                    data?.monthConstructDate
                                )}
                            </MergeGridItem>
                            <MergeGridItem
                                name={
                                    <div className={cx("two-line-text")}>
                                        <span>공사진행</span>
                                        <br />
                                        <span>사유</span>
                                    </div>
                                }
                                span
                            >
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_SUBMIT" ||
                                    data?.state === "M_VERIFY") ? (
                                    <Input
                                        className={cx("input")}
                                        status={inputs.monthConstructGoReason === "" ? "error" : ""}
                                        value={inputs.monthConstructGoReason}
                                        name={"monthConstructGoReason"}
                                        onChange={handleChangeInput}
                                    />
                                ) : (
                                    <span className={cx("cost-reason")}>{data?.monthConstructGoReason}</span>
                                )}
                            </MergeGridItem>
                            <MergeGridItem
                                name={
                                    <div className={cx("two-line-text")}>
                                        <span>공사예가</span>
                                        <br />
                                        <span>제출업체</span>
                                    </div>
                                }
                                span
                            >
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    data?.state === "M_SUBMIT" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_VERIFY") ? (
                                    <Input
                                        className={cx("input")}
                                        value={inputs.monthConstructCompany}
                                        status={inputs.monthConstructCompany === "" ? "error" : ""}
                                        name={"monthConstructCompany"}
                                        onChange={handleChangeInput}
                                    />
                                ) : (
                                    data?.monthConstructCompany
                                )}
                            </MergeGridItem>

                            <MergeGridItem
                                name={"금액"}
                                span={!(data?.state === "M_VERIFY" || data?.state === "M_CONFIRM")}
                            >
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    data?.state === "M_SUBMIT" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_VERIFY") ? (
                                    <InputNumber
                                        className={cx("input")}
                                        value={inputs.monthConstructCost}
                                        status={
                                            inputs.monthConstructCost !== null
                                                ? inputs.monthConstructCost <= 0
                                                    ? "error"
                                                    : ""
                                                : "error"
                                        }
                                        name={"monthConstructCost"}
                                        controls={false}
                                        formatter={(value) => formatCost(value)}
                                        onChange={(value) =>
                                            setInputs((prev) => ({
                                                ...prev,
                                                monthConstructCost: value,
                                            }))
                                        }
                                    />
                                ) : (
                                    data?.monthConstructCost?.toLocaleString() ?? 0
                                )}
                            </MergeGridItem>
                            {(data?.state === "M_VERIFY" || data?.state === "M_CONFIRM") && (
                                <MergeGridItem name={"반영금액"}>
                                    {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                        data?.state === "M_CONFIRM" ? (
                                            data.fixedCost?.toLocaleString() ?? 0
                                        ) : (
                                            <InputNumber
                                                className={cx("input")}
                                                value={fixedCost}
                                                status={fixedCost === null ? "error" : ""}
                                                name={"fixedCost"}
                                                controls={false}
                                                formatter={(value) => formatCost(value)}
                                                onChange={(value) => setFixedCost(value)}
                                            />
                                        )
                                    ) : (
                                        data.fixedCost?.toLocaleString() ?? 0
                                    )}
                                </MergeGridItem>
                            )}
                            <MergeGridItem
                                name={
                                    <div className={cx("two-line-text")}>
                                        <span>마트</span>
                                        <br />
                                        <span>부담금액</span>
                                    </div>
                                }
                            >
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_SUBMIT" ||
                                    data?.state === "M_VERIFY") ? (
                                    <InputNumber
                                        className={cx("input")}
                                        value={inputs.storeContributionAmount}
                                        controls={false}
                                        formatter={(value) => formatCost(value)}
                                        onChange={(value) =>
                                            setInputs((prev) => ({...prev, storeContributionAmount: value}))
                                        }
                                    />
                                ) : (
                                    data?.storeContributionAmount.toLocaleString() ?? 0
                                )}
                            </MergeGridItem>
                            <MergeGridItem name={"상계처리금액"}>
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    costFacilityId === 0 ||
                                    data?.state === "Y_VERIFY" ||
                                    data?.state === "M_SUBMIT" ||
                                    data?.state === "M_VERIFY") ? (
                                    <InputNumber
                                        className={cx("input")}
                                        value={inputs.offsetAmount}
                                        controls={false}
                                        formatter={(value) => formatCost(value)}
                                        onChange={(value) => setInputs((prev) => ({...prev, offsetAmount: value}))}
                                    />
                                ) : (
                                    data?.offsetAmount.toLocaleString() ?? 0
                                )}
                            </MergeGridItem>
                            <MergeGridItem name={"견적서"} span extraClassName={"upload-container"}>
                                <div className={gridCx("file-container")}>
                                    <Upload.Dragger
                                        className={gridCx("upload-dragger", "cost")}
                                        name={"file"}
                                        maxCount={1}
                                        showUploadList={false}
                                        disabled={
                                            data?.state === "M_CONFIRM" ||
                                            data?.state === "M_APPROVAL" ||
                                            data?.rejectStatus === "Y"
                                        }
                                        beforeUpload={(e) => {
                                            setInputs((prev) => ({...prev, monthEstimateFileObj: e}));
                                            return false;
                                        }}
                                    >
                                        {fileName ? (
                                            <List
                                                itemLayout="horizontal"
                                                dataSource={[fileName]}
                                                className={gridCx("file-list")}
                                                renderItem={(file, index) => (
                                                    <List.Item
                                                        className={gridCx("file-list-item")}
                                                        key={index}
                                                        actions={[
                                                            <ButtonAntd
                                                                type="link"
                                                                key={index}
                                                                icon={<FontAwesomeIcon icon={faDownload} />}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    downloadReport();
                                                                }}
                                                                style={{display: costFacilityId === 0 ? "none" : ""}}
                                                            />,
                                                            <ButtonAntd
                                                                type="link"
                                                                danger
                                                                key={index}
                                                                icon={<FontAwesomeIcon icon={faTrashCan} />}
                                                                style={{
                                                                    display:
                                                                        data?.state === "M_CONFIRM" ||
                                                                        data?.state === "M_APPROVAL"
                                                                            ? "none"
                                                                            : "",
                                                                }}
                                                                onClick={async (e) => {
                                                                    e.stopPropagation();
                                                                    Swal.fire({
                                                                        html: "파일을 삭제하시겠습니까? <br/> 파일삭제는 즉시 저장됩니다",
                                                                        showCancelButton: true,
                                                                        confirmButtonText: "확인",
                                                                        cancelButtonText: "취소",
                                                                    }).then(async (info) => {
                                                                        if (info.isConfirmed) {
                                                                            if (costFacilityId !== 0) {
                                                                                await deleteReport();
                                                                            }
                                                                            setFileName(null);
                                                                            setInputs((prev) => ({
                                                                                ...prev,
                                                                                monthEstimateFileObj: null,
                                                                            }));
                                                                        }
                                                                    });
                                                                }}
                                                            />,
                                                        ]}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                                            title={fileName.split("/").pop()}
                                                            className={gridCx("file-list-item-meta")}
                                                            style={{textAlign: "left"}}
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        ) : (
                                            <>
                                                {data?.state === "M_CONFIRM" || data?.rejectStatus === "Y" ? (
                                                    <p>첨부된 파일이 없습니다</p>
                                                ) : (
                                                    <p>드래그하여 파일을 업로드 하거나 파일을 선택하세요</p>
                                                )}
                                            </>
                                        )}
                                    </Upload.Dragger>
                                </div>
                            </MergeGridItem>
                            <MergeGridItem name={"비고"} span>
                                {data?.rejectStatus !== "Y" &&
                                (data?.state === "Y_CONFIRM" ||
                                    data?.state === "Y_VERIFY" ||
                                    costFacilityId === 0 ||
                                    data?.state === "M_SUBMIT" ||
                                    data?.state === "M_VERIFY") ? (
                                    <Input.TextArea
                                        className={cx("text-area")}
                                        style={{resize: "none"}}
                                        value={inputs.monthMemo}
                                        onChange={(e) => setInputs((prev) => ({...prev, monthMemo: e.target.value}))}
                                    />
                                ) : (
                                    <pre className={cx("text-area-text")}> {data?.monthMemo}</pre>
                                )}
                            </MergeGridItem>
                        </div>
                    </div>
                    {data?.rejectStatus === "Y" && (
                        <div className={cx("reject-reason")}>
                            <MergeGridItem name={"반려 사유"} span>
                                <pre className={cx("reason")}>{data.rejectReason}</pre>
                            </MergeGridItem>
                        </div>
                    )}
                </>
            )}
        </SideModal>
    );
};

export {CostFacilityMaintenanceModal};
