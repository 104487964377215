import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Table} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import {useCostIntegratedList} from "~/data/cost/integrated";

import styles from "./pc-dashboard.page.module.scss";
import tableStyles from "~/components/custom-label-table/custom-label-table.module.scss";

const cx = classNames.bind(styles);
const tableCx = classNames.bind(tableStyles);

const CostIntegratedTable = () => {
    const navigate = useNavigate();

    const [submit, response, isLoading] = useCostIntegratedList({
        date: {
            year: dayjs(),
            quarter: null,
            month: dayjs(),
        },
        costRange: {
            min: null,
            max: null,
        },
    });

    useEffect(() => {
        submit();
    }, []);

    return (
        <Table
            pagination={false}
            bordered
            className={cx("table")}
            rowKey={"trainerSectorId"}
            dataSource={response?.integratedRepairCostList}
            loading={isLoading}
            onRow={(record) => {
                return {
                    style: {
                        backgroundColor: record.trainerSectorName === "합계" ? "#ffefef" : "",
                    },
                };
            }}
            columns={[
                {
                    title: "부문명",
                    key: "trainerSectorName",
                    dataIndex: "trainerSectorName",
                    className: tableCx("vertical"),
                    align: "center",
                },
                {
                    title: "점포수",
                    children: [
                        {
                            title: "점포수",
                            key: "buildingCnt",
                            dataIndex: "buildingCnt",
                            align: "center",
                        },
                        {
                            title: "구성비",
                            key: "compositionRatio",
                            dataIndex: "compositionRatio",
                            align: "center",
                        },
                        {
                            title: "배정금액",
                            key: "amountAllocation",
                            dataIndex: "amountAllocation",
                            render: (value) => (value ? value.toLocaleString() : 0),
                            align: "right",
                        },
                    ],
                },
                {
                    title: `요청 금액 (${response?.totalAllocationAmount} 기준)`,
                    className: tableCx("parent-column"),
                    children: [
                        {
                            title: "기타유형자산",
                            key: "totalOtherTangible",
                            dataIndex: "totalOtherTangible",
                            render: (value) => (value ? value.toLocaleString() : 0),
                            align: "right",
                            onCell: (record) => ({
                                onClick: () => {
                                    navigate(
                                        record.trainerSectorName === "합계"
                                            ? `/cost/facility?dates=${dayjs().format("YYYY")},${dayjs().format("MM")}`
                                            : `/cost/facility?typeId=LC028_2&trainerSectorId=${record.trainerSectorId}&dates=${dayjs().format("YYYY")},${dayjs().format("MM")}`,
                                    );
                                },
                            }),
                        },
                        {
                            title: "안전시설공사",
                            key: "totalSafetyFacility",
                            dataIndex: "totalSafetyFacility",
                            align: "right",
                            render: (value) => (value ? value.toLocaleString() : 0),
                            onCell: (record) => ({
                                onClick: () => {
                                    navigate(
                                        record.trainerSectorName === "합계"
                                            ? `/cost/facility?dates=${dayjs().format("YYYY")},${dayjs().format("MM")}`
                                            : `/cost/facility?typeId=LC028_1&trainerSectorId=${record.trainerSectorId}&dates=${dayjs().format("YYYY")},${dayjs().format("MM")}`,
                                    );
                                },
                            }),
                        },
                        {
                            title: "등기구 구입비",
                            key: "totalLightFixture",
                            dataIndex: "totalLightFixture",
                            align: "right",
                            render: (value) => (value ? value.toLocaleString() : 0),
                            onCell: (record) => ({
                                onClick: () => {
                                    navigate(
                                        record.trainerSectorName === "합계"
                                            ? `/cost/maintenance?dates=${dayjs().format("YYYY")},${dayjs().format("MM")}`
                                            : `/cost/maintenance?trainerSectorId=${record.trainerSectorId}&dates=${dayjs().format("YYYY")},${dayjs().format("MM")}`,
                                    );
                                },
                            }),
                        },
                        {
                            title: "수선유지비품 구입비",
                            key: "totalRepairMaintenance",
                            dataIndex: "totalRepairMaintenance",
                            align: "right",
                            render: (value) => (value ? value.toLocaleString() : 0),
                            onCell: (record) => ({
                                onClick: () => {
                                    navigate(
                                        record.trainerSectorName === "합계"
                                            ? `/cost/maintenance?dates=${dayjs().format("YYYY")},${dayjs().format("MM")}`
                                            : `/cost/maintenance?trainerSectorId=${record.trainerSectorId}&dates=${dayjs().format("YYYY")},${dayjs().format("MM")}`,
                                    );
                                },
                            }),
                        },
                        {
                            title: "소계",
                            key: "subtotal",
                            align: "right",
                            dataIndex: "subtotal",
                            render: (value) => (value ? value.toLocaleString() : 0),
                        },
                    ],
                },
            ]}
        />
    );
};

export default CostIntegratedTable;
