import React, {Fragment, useCallback, useEffect, useState} from "react";
import {ResponsiveBar} from "@nivo/bar";
import {ResponsivePie} from "@nivo/pie";
import {Empty} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {CnRangePicker} from "~/components/cn-range-picker";
import {CnSelect} from "~/components/cn-select";
import {CnTable} from "~/components/cn-table/cn-table";
import {FilterArea} from "~/components/filter-area";
import {PageTitle} from "~/components/page-title";
import {useCheckElevator, useCheckElevatorList} from "~/data/check/elevator/use-check-elevator";
import {CheckElevatorResponse, CheckElevatorStatus} from "~/data/check/elevator/use-check-elevator.interface";
import {useUser} from "~/data/user";
import {useElevatorCompany} from "~/hooks/use-elevator-company/use-elevator-company";
import {useShop} from "~/hooks/use-shop/use-shop";
import {ElevatorCheckStatus, ElevatorPieChartDataType} from "~/pages/check/elevator/elevator-check.interface";
import {ElevatorCheckRequestModal} from "~/pages/check/elevator/request/elevator-check-request.modal";
import {ElevatorCheckRequestEditModal} from "~/pages/check/elevator/request/elevator-check-request-edit.modal";

import styles from "./elevator-check.page.module.scss";

const cx = classNames.bind(styles);

const ElevatorCheckPage = () => {
    const perPage = 50;
    const [page, setPage] = useState(1);
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([dayjs(new Date()).add(-6, "d"), dayjs(new Date())]);
    const [buildingId, setBuildingId] = useState<number>(0);
    const [status, setStatus] = useState<ElevatorCheckStatus | null>(null);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isShowDetailModal, setIsShowDetailModal] = useState<false | number>(false);
    const [pieChartData, setPieChartData] = useState<ElevatorPieChartDataType[]>([]);
    const [company, setCompany] = useState<string | null>(null);
    const [penalty, setPenalty] = useState<"YES" | "NO" | null>(null);

    const {role, buildingId: buildingIdFromUser} = useUser();
    const {companies} = useElevatorCompany();

    const {deleteReport} = useCheckElevator();
    const {loading, summary, list, statusValueByKey, totalCount, refresh, downloadReport} = useCheckElevatorList({
        ignore: role === undefined,
        perPage,
        page,
        buildingId: buildingId === 0 ? undefined : buildingId,
        checkStatus: status as ElevatorCheckStatus,
        startDate: dateRange && dateRange[0].format("YYYY-MM-DD"),
        endDate: dateRange && dateRange[1].format("YYYY-MM-DD"),
        company,
        penalty,
    });
    const allData = useCheckElevatorList({
        ignore: role === undefined,
        perPage: 99999,
        page: 1,
        buildingId: buildingId === 0 ? undefined : buildingId,
        checkStatus: status as ElevatorCheckStatus,
        startDate: dateRange && dateRange[0].format("YYYY-MM-DD"),
        endDate: dateRange && dateRange[1].format("YYYY-MM-DD"),
        company,
        penalty,
    });

    useEffect(() => {
        if (role === "MANAGER" || role === "EMPLOYEE") setBuildingId(buildingIdFromUser!);
    }, [buildingIdFromUser]);

    const handleResetFilter = () => {
        setPage(1);
        setStatus(null);
        setDateRange([dayjs(new Date()).add(-6, "d"), dayjs(new Date())]);
        if (role === "MANAGER" || role === "EMPLOYEE") setBuildingId(buildingIdFromUser!);
        else {
            shopReset();
        }
    };

    const handleSubmit = () => {
        setPage(1);
        refresh();
        allData.refresh();
    };

    const handleNewRequest = () => {
        setIsShowModal(true);
    };

    const {shop, reset: shopReset, ShopSelect} = useShop();

    const getBrandColor = useCallback((brand: string) => {
        switch (brand) {
            case "오티스":
                return "#00539C"; // 깊은 파란색
            case "현대":
                return "#6AABD2"; // 밝은 하늘색
            case "쉰들러":
                return "#a10b23"; // 강렬한 빨강
            case "미쯔비시":
                return "#E60012"; // 선명한 빨강
            case "기타":
                return "#b3b3b3"; // 중립적인 그레이
            default:
                return "#000000"; // 기본값: 블랙
        }
    }, []);

    useEffect(() => {
        setBuildingId(shop);
    }, [shop]);
    useEffect(() => {
        const format = companies.map((company) => ({
            id: company,
            label: company,
            value: 0,
        }));

        const temp = allData.list.filter((item) => item.penalty === "YES");
        const counts: Record<string, number> = {};
        temp.forEach((item) => {
            const key = companies.includes(item.company) ? item.company : "기타";
            counts[key] = (counts[key] || 0) + 1;
        });

        const result = [
            ...format.map((company) => ({
                id: company.id,
                label: company.label,
                value: counts[company.id] || 0, // 카운트 값이 없으면 기본값 0
                color: getBrandColor(company.id),
            })),
            {
                id: "기타",
                label: "기타",
                value: counts["기타"] || 0, // 기타 항목 합산
                color: getBrandColor("기타"),
            },
        ];

        setPieChartData(result);
    }, [allData.list, companies]);

    return (
        <div>
            {isShowModal && <ElevatorCheckRequestModal close={() => setIsShowModal(false)} refresh={refresh} />}
            {isShowDetailModal && (
                <ElevatorCheckRequestEditModal
                    checkId={isShowDetailModal}
                    close={() => setIsShowDetailModal(false)}
                    refresh={refresh}
                />
            )}
            <PageTitle>승강기 고장 점검 목록</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <CnRangePicker
                        label={"기간"}
                        value={dateRange}
                        onChange={(value) => {
                            if (!value) setDateRange([dayjs(new Date()).add(-6, "d"), dayjs(new Date())]);
                            else setDateRange(value);
                        }}
                    />
                    {role === "MEMBER" && (
                        <Fragment>
                            <ShopSelect />
                        </Fragment>
                    )}
                    <CnSelect
                        label={"점검상태"}
                        showSearch
                        placeholder="점검상태"
                        onChange={(value) => setStatus(value)}
                        value={status}
                        options={[
                            {
                                value: null,
                                label: "전체",
                            },
                            {
                                value: "REQUEST",
                                label: "접수완료",
                            },
                            {
                                value: "ING",
                                label: "수리 중",
                            },
                            {
                                value: "COMPLETED",
                                label: "수리 완료",
                            },
                        ]}
                    />
                    <CnSelect
                        label={"업체명"}
                        value={company || 0}
                        onChange={(value) => setCompany(value === 0 ? null : value)}
                        options={[
                            {
                                label: "전체",
                                value: 0,
                            },
                            ...companies.map((item) => ({label: item, value: item})),
                        ]}
                    />
                    <CnSelect
                        label={"패널티"}
                        value={penalty || ""}
                        onChange={(e) => setPenalty(e === "" ? null : e)}
                        options={[
                            {
                                label: "전체",
                                value: "",
                            },
                            {
                                label: "YES",
                                value: "YES",
                            },
                            {
                                label: "NO",
                                value: "NO",
                            },
                        ]}
                    />
                    <div className={cx("button-container")}>
                        <Button onClick={handleSubmit} label={"조회"} color={"primary"} />
                        <Button onClick={handleResetFilter} label={"검색조건 초기화"} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("chart-container")}>
                <div className={cx("sankey-container")}>
                    {summary.ING === 0 && summary.penalty_below === 0 && summary.penalty_above === 0 ? (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{margin: "auto"}} />
                    ) : (
                        <>
                            <div className={cx("pie-container")}>
                                <span className={cx("text")}>패널티 집계</span>
                                <ResponsivePie
                                    data={pieChartData}
                                    margin={{top: 10, right: 90, bottom: 10, left: 0}}
                                    innerRadius={0}
                                    padAngle={0}
                                    activeOuterRadiusOffset={8}
                                    colors={(data) => data.data.color}
                                    enableArcLinkLabels={false}
                                    arcLabelsSkipAngle={40}
                                    arcLabelsTextColor={"white"}
                                    legends={[
                                        {
                                            anchor: "right",
                                            direction: "column",
                                            justify: false,
                                            translateX: 100,
                                            translateY: 0,
                                            itemWidth: 100,
                                            itemHeight: 20,
                                            itemsSpacing: 5,
                                            symbolSize: 17,
                                            itemDirection: "left-to-right",
                                        },
                                    ]}
                                />
                            </div>
                            <ResponsiveBar
                                data={[
                                    {
                                        name: "점검요청",
                                        value: summary.REQUEST,
                                        color: "rgba(129,133,232,0.76)",
                                    },
                                    {
                                        name: "수리 중",
                                        value: summary.ING,
                                        color: "rgba(255,151,20,0.68)",
                                    },
                                    {
                                        name: "1시간 이내",
                                        value: summary.penalty_below,
                                        color: "rgba(73,159,38,0.71)",
                                    },
                                    {
                                        name: "1시간 초과",
                                        value: summary.penalty_above,
                                        color: "rgba(218,82,82,0.73)",
                                    },
                                    {
                                        name: "수리 완료",
                                        value: summary.COMPLETED,
                                        color: "rgba(130,81,205,0.71)",
                                    },
                                ].reverse()}
                                keys={["value"]}
                                indexBy={"name"}
                                margin={{top: 0, right: 30, bottom: 25, left: 80}}
                                padding={0.3}
                                layout={"horizontal"}
                                colors={(bar) => bar.data.color}
                                tooltipLabel={(datum) => datum.data.name}
                                theme={{
                                    labels: {
                                        text: {
                                            fontSize: 12,
                                        },
                                    },
                                    axis: {
                                        ticks: {
                                            text: {
                                                fontSize: 13,
                                            },
                                        },
                                    },
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className={cx("table-container")}>
                {role !== "MEMBER" && (
                    <div className={cx("button-container")}>
                        <Button onClick={handleNewRequest} label={"승강기 점검 요청"} />
                    </div>
                )}
                <CnTable
                    totalCount={totalCount}
                    onPageChange={(page) => setPage(page)}
                    perPage={perPage}
                    page={page}
                    dataSource={list?.map((row: any) => {
                        row["key"] = row.liftId;
                        return row;
                    })}
                    pagination={false}
                    loading={loading}
                    onRow={(row) => ({onClick: () => setIsShowDetailModal(row.liftId)})}
                    columns={[
                        {
                            title: "No",
                            key: "index",
                            render: (_, __, index) => Number((page - 1) * perPage + index + 1).toLocaleString(),
                            align: "center",
                        },
                        {
                            title: "발생일시",
                            dataIndex: "breakDate",
                            key: "breakDate",
                            align: "center",
                        },
                        {
                            title: "도착시간",
                            dataIndex: "arriveTime",
                            key: "arriveTime",
                            align: "center",
                        },
                        {
                            title: "권역",
                            dataIndex: "trainerSectorName",
                            key: "trainerSectorName",
                            align: "center",
                        },
                        {
                            title: "지점",
                            dataIndex: "buildingName",
                            key: "buildingName",
                            align: "center",
                        },
                        {
                            title: "구역",
                            dataIndex: "breakLocation",
                            key: "breakLocation",
                            align: "center",
                            render: (_, row) => row.breakLocation + " " + row.breakUnit,
                        },
                        {
                            title: "점검자",
                            dataIndex: "employeeName",
                            key: "employeeName",
                            align: "center",
                        },
                        {
                            title: "패널티",
                            dataIndex: "penalty",
                            key: "penalty",
                            align: "center",
                        },
                        {
                            title: "점검상태",
                            dataIndex: "checkStatus",
                            key: "checkStatus",
                            align: "center",
                            render: (data: CheckElevatorStatus) => statusValueByKey[data],
                        },
                        {
                            title: "내려받기",
                            dataIndex: "liftId",
                            key: "liftId",
                            align: "center",
                            width: "8%",
                            render: (data, row: CheckElevatorResponse) =>
                                row.checkStatus === "COMPLETED" && (
                                    <Button
                                        label={"내려받기"}
                                        color={"primary"}
                                        size="small"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            downloadReport(row.liftId);
                                        }}
                                    />
                                ),
                        },
                        {
                            title: "삭제",
                            dataIndex: "liftId",
                            key: "liftId",
                            align: "center",
                            width: "5%",
                            render: (data, row: CheckElevatorResponse) => (
                                <Button
                                    label={"삭제"}
                                    size="small"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        Swal.fire({
                                            title: "정말 삭제하시겠습니까?",
                                            showCancelButton: true,
                                            confirmButtonText: "삭제하기",
                                            cancelButtonText: "취소하기",
                                        }).then(async (result) => {
                                            if (result.isConfirmed) {
                                                await deleteReport(row.liftId);
                                                setTimeout(refresh, 500);
                                            }
                                        });
                                    }}
                                />
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {ElevatorCheckPage};
