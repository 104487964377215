import {useCallback, useState} from "react";
import {Select} from "antd";

const useElevatorCompany = () => {
    const [companies, setCompanies] = useState<string[]>(["오티스", "현대", "쉰들러", "미쯔비시"]);
    const [company, setCompany] = useState<string | null>(null);

    const reset = useCallback(() => {
        setCompany(null);
    }, []);

    const SelectCompany = () => {
        return (
            <Select
                value={company}
                onChange={(e) => setCompany(e)}
                placeholder={"업체 선택"}
                options={companies.map((item) => ({
                    label: item,
                    value: item,
                }))}
                getPopupContainer={(t) => t.parentNode}
                style={{flex: 1}}
            />
        );
    };

    return {companies, SelectCompany, company, setCompany, resetCompany: reset};
};

export {useElevatorCompany};
