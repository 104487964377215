import {useMemo, useState} from "react";
import {message} from "antd";
import Swal from "sweetalert2";

import {httpRequest} from "~/fetch/common/http-request";

import {
    CostFacilityMemberConfig,
    CostFacilityMemberFixRequest,
    CostFacilityRejectRequest,
} from "./cost-facility-member.interface";

// 본사 확인
const useCostFacilityMemberConfirm = (
    config: CostFacilityMemberConfig,
): [() => Promise<void>, ({rejectReason}: {rejectReason: string}) => Promise<void>, any] => {
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<{costFacilityId: number} | {costFacilityId: number}[], null>({
            method: "PUT",
            url: `/lotte/cost/facility/month/plans/plan/construct/confirm${config.selectedCostFacilityId ? "/list" : ""}`,
            data: config.selectedCostFacilityId
                ? config.selectedCostFacilityId.map((data) => ({
                      costFacilityId: Number(data),
                  }))
                : {
                      costFacilityId: config.costFacilityId,
                  },
        });
    }, [config]);

    const submit = async () => {
        try {
            await request.submit();
        } catch (e) {
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
            console.log(e);
            setError(e);
        }
    };

    const reject = async ({rejectReason}: {rejectReason: string}) => {
        await httpRequest<CostFacilityRejectRequest, any>({
            method: "PUT",
            url: "/lotte/cost/facility/month/plans/plan/reject",
            data: {
                costFacilityId: config.costFacilityId,
                rejectStatus: "Y",
                rejectReason,
            },
        })
            .submit()
            .then(() => message.success("저장되었습니다."))
            .catch(() => {
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            });
    };

    return [submit, reject, error];
};

// 승인
const useCostFacilityMemberApproval = (config: CostFacilityMemberConfig): [() => Promise<void>, any] => {
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<{costFacilityId: number} | {costFacilityId: number}[], null>({
            method: "PUT",
            url: `/lotte/cost/facility/month/plans/plan/approvals/approval${config.selectedCostFacilityId ? "/list" : ""}`,
            data: config.selectedCostFacilityId
                ? config.selectedCostFacilityId.map((data) => ({
                      costFacilityId: Number(data),
                  }))
                : {
                      costFacilityId: config.costFacilityId,
                  },
        });
    }, [config]);

    const submit = async () => {
        try {
            await request.submit();
        } catch (e) {
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
            console.log(e);
            setError(e);
        }
    };
    return [submit, error];
};

// 확정
//TODO 다중 확정 시 반영금액 0원으로 일괄 적용
const useCostFacilityMemberFix = (config: CostFacilityMemberFixRequest) => {
    const [error, setError] = useState<any>(null);
    const request = useMemo(() => {
        return httpRequest<CostFacilityMemberFixRequest | {costFacilityId: number}[], null>({
            method: "PUT",
            url: `/lotte/cost/facility/month/plans/plan/finalConfirms/confirm${config.selectedCostFacilityId ? "/list" : ""}`,
            data: config.selectedCostFacilityId
                ? config.selectedCostFacilityId.map((data) => ({
                      costFacilityId: Number(data),
                  }))
                : {costFacilityId: config.costFacilityId, fixedCost: config.fixedCost},
        });
    }, [config]);

    const submit = async () => {
        try {
            await request.submit();
        } catch (e) {
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
            console.log(e);
            setError(e);
        }
    };

    return [submit, error];
};

export {useCostFacilityMemberApproval, useCostFacilityMemberConfirm, useCostFacilityMemberFix};
