import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {ResponsivePie} from "@nivo/pie";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import Button from "~/components/button";
import {CnSelect} from "~/components/cn-select";
import {CnTable} from "~/components/cn-table/cn-table";
import {FilterArea} from "~/components/filter-area";
import {FilterItem} from "~/components/filter-item/filter-item";
import {PageTitle} from "~/components/page-title";
import {useMachineProcess} from "~/data/machine/board/machine";
import {useMachineCascader} from "~/data/machine/board/machine-cascader";
import {useMachineBoardChart, useMachineBoardList} from "~/data/machine/board/use-machine";
import {useUser} from "~/data/user";
import {useLocalStorage} from "~/utills/useLocalStorage";

import {EquipmentDetailModal} from "./detail/equipment-detail.modal";
import {EquipmentDetailEditModal} from "./detail/equipment-detail-edit.modal";
import {EquipmentBoardRegisterModal} from "./register/equipment-board-register.modal";

import styles from "./equipment.module.scss";

const cx = classNames.bind(styles);

const EquipmentPage = () => {
    const perPage = useMemo(() => 15, []);
    const [page, setPage] = useState(1);
    const [division, setDivision] = useState<"MEMBER" | "MANAGER" | "EMPLOYEE" | null>(null);
    const [searchMachineUseYn, setSearchMachineUseYn] = useState<"Y" | "N" | "ALL">("ALL");
    const [isNoImage, setIsNoImage] = useState<"UPLOADED" | "NOT_UPLOAD" | "ALL">("ALL");
    const [trainerId, setTrainerId] = useState(null);
    const [sectorId, setSectorId] = useState(null);
    const [buildingId, setBuildingId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState<{
        machineCategoryCode: number;
        machineId: number;
        companyId: number;
        buildingId: number;
    } | null>(null);
    const [isModalRegisterOpen, setIsModalRegisterOpen] = useState<{buildingName: string; buildingId: number} | null>(
        null,
    );

    const {t} = useTranslation();
    const {setItem, getItem} = useLocalStorage();

    const {user} = useUser();

    const {ProcessSelect, process, category, reset: handleResetProcess} = useMachineProcess();
    const {MachineCascader, selected, reset: handleResetSector} = useMachineCascader(250);
    const [chartResponse, isLoadingChart] = useMachineBoardChart();
    const [submit, response, isLoading] = useMachineBoardList({
        pageNo: page, // 페이지 넘버 - 1(ALL)
        trainerSectorId: trainerId, // 트레이너 권역
        searchMachineSectorId: sectorId, // 부문
        searchMachineBuildingId: buildingId, // 지점
        searchProcessCode: process, // 공정
        searchMachineCategoryCode: category, // 카테고리 - ALL
        searchMachineUseYn: searchMachineUseYn, // 사용여부 - ALL
        isNoImage: isNoImage, // 설비 사진 - ALL
    });

    useEffect(() => {
        const updateValues = () => {
            let tempTrainer: any = null;
            let tempSector: any = null;
            let tempBuilding: any = null;

            if (selected[0] === "ALL") {
                tempTrainer = null;
                tempSector = null;
                tempBuilding = null;
            } else if (selected[0] === "TRAINER") {
                tempTrainer = selected[1] !== "ALL" ? selected[1] : null;
                tempSector = null;
                tempBuilding = selected[2] !== "ALL" ? selected[2] : null;
            } else if (selected[0] === "SECTOR") {
                tempTrainer = null;
                tempSector = selected[1] !== "ALL" ? selected[1] : null;
                tempBuilding = selected[2] !== "ALL" ? selected[2] : null;
            }

            // 업데이트
            setTrainerId(tempTrainer);
            setSectorId(tempSector);
            setBuildingId(tempBuilding);
        };

        updateValues();
    }, [selected]);

    // 계정 권한
    useEffect(() => {
        setDivision(JSON.parse(getItem("LM_USER")!).division);
    }, [getItem]);

    useEffect(() => {
        // window.scrollTo(0, 0);
        submit();
    }, [page]);

    const handleSubmit = async () => {
        setPage(1);
        submit();
    };

    const handleResetFilter = () => {
        setPage(1);
        handleResetProcess();
        handleResetSector();
        setSearchMachineUseYn("ALL");
        setIsNoImage("ALL");
        submit();
    };

    return (
        <Fragment>
            {/* 등록 - (지점) */}
            {isModalRegisterOpen && division === "MANAGER" && (
                <EquipmentBoardRegisterModal
                    defaultData={isModalRegisterOpen}
                    onClose={() => {
                        setIsModalRegisterOpen(null);
                    }}
                    refresh={submit}
                />
            )}
            {/* 상세 및 수정, 삭제 - (지점) */}
            {isModalOpen && division === "MANAGER" && (
                <EquipmentDetailEditModal
                    machineId={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(null);
                    }}
                    refresh={submit}
                    division={division}
                />
            )}
            {/* 상세 - (본사) */}
            {isModalOpen && division === "MEMBER" && (
                <EquipmentDetailModal
                    machineId={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(null);
                    }}
                    division={division}
                />
            )}
            <div>
                <PageTitle>{t("page.machine.status")}</PageTitle>
                <FilterArea>
                    <div className={cx("filter-inner")}>
                        {/* 검색 */}
                        {division === "MEMBER" && (
                            <FilterItem label={t("filter.department-trainer")}>
                                <MachineCascader />
                            </FilterItem>
                        )}
                        <ProcessSelect />
                        <CnSelect
                            label={t("filter.use-status")}
                            placeholder={t("filter.use-status")}
                            onChange={(e) => setSearchMachineUseYn(e)}
                            value={searchMachineUseYn}
                            options={[
                                {
                                    value: "ALL",
                                    label: "전체",
                                },
                                {
                                    value: "Y",
                                    label: "사용",
                                },
                                {
                                    value: "N",
                                    label: "사용안함",
                                },
                            ]}
                            style={{width: 120}}
                        />
                        {/*<CnSelect*/}
                        {/*    label={"설비사진"}*/}
                        {/*    placeholder={"설비사진"}*/}
                        {/*    onChange={(e) => setIsNoImage(e)}*/}
                        {/*    value={isNoImage}*/}
                        {/*    options={[*/}
                        {/*        {*/}
                        {/*            value: "ALL",*/}
                        {/*            label: "전체",*/}
                        {/*        },*/}
                        {/*        {*/}
                        {/*            value: "UPLOADED",*/}
                        {/*            label: "등록",*/}
                        {/*        },*/}
                        {/*        {*/}
                        {/*            value: "NOT_UPLOAD",*/}
                        {/*            label: "미등록",*/}
                        {/*        },*/}
                        {/*    ]}*/}
                        {/*    style={{width: 120}}*/}
                        {/*/>*/}
                        <div className={cx("button-container")}>
                            <Button onClick={handleSubmit} label={t("common.search")} color={"primary"} />
                            <Button onClick={handleResetFilter} label={t("common.reset")} color={"primary"} />
                            {division === "MANAGER" && (
                                <Button
                                    onClick={() => {
                                        setIsModalRegisterOpen({
                                            buildingName: user?.buildingName || "",
                                            buildingId: user?.buildingId || 0,
                                        });
                                    }}
                                    label={t("filter.register")}
                                />
                            )}
                        </div>
                    </div>
                </FilterArea>
                <div className={cx("chart-container")}>
                    <div className={cx("chart")}>
                        <label>{t("filter.department")}</label>
                        <ResponsivePie
                            enableArcLinkLabels={false}
                            data={[
                                {
                                    id: "수도권1",
                                    label: "수도권1",
                                    value: chartResponse?.sector1,
                                },
                                {
                                    id: "수도권2",
                                    label: "수도권2",
                                    value: chartResponse?.sector2,
                                },
                                {
                                    id: "광주호남",
                                    label: "광주호남",
                                    value: chartResponse?.sector3,
                                },
                                {
                                    id: "영남",
                                    label: "영남",
                                    value: chartResponse?.sector4,
                                },
                                {
                                    id: "대전충청",
                                    label: "대전충청",
                                    value: chartResponse?.sector5,
                                },
                                {
                                    id: "폐업",
                                    label: "폐업",
                                    value: chartResponse?.sector6,
                                },
                            ]}
                            margin={{top: 10, right: 10, bottom: 10, left: 10}}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{
                                from: "color",
                                modifiers: [["darker", 0.2]],
                            }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{from: "color"}}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: "color",
                                modifiers: [["darker", 2]],
                            }}
                            defs={[
                                {
                                    id: "dots",
                                    type: "patternDots",
                                    background: "inherit",
                                    color: "rgba(255, 255, 255, 0.3)",
                                    size: 4,
                                    padding: 1,
                                    stagger: true,
                                },
                                {
                                    id: "lines",
                                    type: "patternLines",
                                    background: "inherit",
                                    color: "rgba(255, 255, 255, 0.3)",
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10,
                                },
                            ]}
                        />
                    </div>
                    <div className={cx("chart")}>
                        <label>{t("filter.process")}</label>
                        <ResponsivePie
                            enableArcLinkLabels={false}
                            data={[
                                {
                                    id: "건축",
                                    label: "건축",
                                    value: chartResponse?.building,
                                },
                                {
                                    id: "전기",
                                    label: "전기",
                                    value: chartResponse?.electricity,
                                },
                                {
                                    id: "기계",
                                    label: "기계",
                                    value: chartResponse?.machine,
                                },
                                {
                                    id: "소방",
                                    label: "소방",
                                    value: chartResponse?.firefighting,
                                },
                                {
                                    id: "냉동냉장",
                                    label: "냉동냉장",
                                    value: chartResponse?.freezing,
                                },
                                {
                                    id: "승강기",
                                    label: "승강기",
                                    value: chartResponse?.elevator,
                                },
                                {
                                    id: "오터소터",
                                    label: "오터소터",
                                    value: chartResponse?.autoSorter,
                                },
                            ]}
                            margin={{top: 10, right: 10, bottom: 10, left: 10}}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{
                                from: "color",
                                modifiers: [["darker", 0.2]],
                            }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{from: "color"}}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: "color",
                                modifiers: [["darker", 2]],
                            }}
                            defs={[
                                {
                                    id: "dots",
                                    type: "patternDots",
                                    background: "inherit",
                                    color: "rgba(255, 255, 255, 0.3)",
                                    size: 4,
                                    padding: 1,
                                    stagger: true,
                                },
                                {
                                    id: "lines",
                                    type: "patternLines",
                                    background: "inherit",
                                    color: "rgba(255, 255, 255, 0.3)",
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10,
                                },
                            ]}
                        />
                    </div>
                    <div className={cx("chart")}>
                        <label>{t("filter.use-status")}</label>
                        <ResponsivePie
                            enableArcLinkLabels={false}
                            data={[
                                {
                                    id: t("filter.in-use"),
                                    label: t("filter.in-use"),
                                    value: chartResponse?.useY,
                                },
                                {
                                    id: t("filter.not-in-use"),
                                    label: t("filter.not-in-use"),
                                    value: chartResponse?.useN,
                                },
                            ]}
                            margin={{top: 10, right: 10, bottom: 10, left: 10}}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            borderWidth={1}
                            borderColor={{
                                from: "color",
                                modifiers: [["darker", 0.2]],
                            }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{from: "color"}}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: "color",
                                modifiers: [["darker", 2]],
                            }}
                            defs={[
                                {
                                    id: "dots",
                                    type: "patternDots",
                                    background: "inherit",
                                    color: "rgba(255, 255, 255, 0.3)",
                                    size: 4,
                                    padding: 1,
                                    stagger: true,
                                },
                                {
                                    id: "lines",
                                    type: "patternLines",
                                    background: "inherit",
                                    color: "rgba(255, 255, 255, 0.3)",
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10,
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className={cx("table-container")}>
                    <CnTable
                        totalCount={response?.allRecordCnt}
                        onPageChange={(page) => setPage(page)}
                        perPage={perPage}
                        page={page}
                        dataSource={response?.resultList.map((row: any) => {
                            row["key"] = row.machineId;
                            return row;
                        })}
                        pagination={false}
                        loading={isLoading}
                        onRow={(data, index) => ({
                            onClick: () =>
                                setIsModalOpen({
                                    machineCategoryCode: data!.machineCategoryCode,
                                    machineId: data!.machineId,
                                    companyId: data!.companyId,
                                    buildingId: data!.buildingId,
                                }),
                        })}
                        columns={[
                            {
                                title: "No",
                                key: "index",
                                render: (_, __, index) => Number((page - 1) * perPage + index + 1).toLocaleString(),
                                align: "center",
                            },
                            {
                                title: t("equipment.branch"),
                                dataIndex: "buildingName",
                                key: "buildingName",
                                align: "center",
                            },
                            {
                                title: t("equipment.process"),
                                dataIndex: "process",
                                key: "process",
                                align: "center",
                            },
                            {
                                title: t("equipment.category"),
                                dataIndex: "machineCategoryName",
                                key: "machineCategoryName",
                                align: "center",
                            },
                            {
                                title: t("equipment.equipment-name"),
                                dataIndex: "machineName",
                                key: "machineName",
                                align: "center",
                            },
                            {
                                title: t("equipment.manufacturer"),
                                dataIndex: "machineManufactureCompany",
                                key: "machineManufactureCompany",
                                align: "center",
                            },
                            {
                                title: t("equipment.installation-date"),
                                dataIndex: "machineInstallDate",
                                key: "machineInstallDate",
                                align: "center",
                                render: (date) => (dayjs(date).isValid() ? dayjs(date).format("YYYY-MM") : ""),
                            },
                            {
                                title: t("equipment.installer"),
                                dataIndex: "machineInstallCompany",
                                key: "machineInstallCompany",
                                align: "center",
                            },
                            {
                                title: t("equipment.use-status"),
                                dataIndex: "machineUseYn",
                                key: "machineUseYn",
                                align: "center",
                            },
                            {
                                title: t("equipment.last-modified-date"),
                                dataIndex: "updateDate",
                                key: "updateDate",
                                align: "center",
                                render: (date) =>
                                    dayjs(date).isValid() ? dayjs(date).format("YYYY-MM-DD hh:mm:ss") : "",
                            },
                        ]}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export {EquipmentPage};
