import {useEffect, useMemo, useRef, useState} from "react";
import {message} from "antd";

import {axiosInstance} from "~/fetch/common/axios-instance";
import {httpRequest} from "~/fetch/common/http-request";

import {
    CheckElevatorListParams,
    CheckElevatorRequestForm,
    CheckElevatorResponse,
    CheckElevatorSummary,
    CheckElevatorUpdateForm,
} from "./use-check-elevator.interface";

function useCheckElevatorList({
    ignore = false,
    perPage,
    page,
    buildingId,
    trainerSectorId,
    checkStatus,
    endDate,
    startDate,
    company,
    penalty,
}: CheckElevatorListParams) {
    const [loading, setLoading] = useState<boolean>(false);
    const [list, setList] = useState<Array<CheckElevatorResponse>>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [summary, setSummary] = useState<CheckElevatorSummary>({
        penalty_below: 0,
        COMPLETED: 0,
        ING: 0,
        REQUEST: 0,
        penalty_above: 0,
        CONFIRM: 0,
    });

    const requestAbortController = useRef(new AbortController());
    const request = useMemo(() => {
        return httpRequest<
            undefined,
            {
                liftCheckSearchList: Array<CheckElevatorResponse>;
                liftSearchVO: {
                    allLiftCnt: number;
                };
            }
        >({
            url: `/liftCheck/liftCheckList`,
            params: {
                linePerPage: perPage,
                pageNo: page,
                buildingId,
                trainerSectorId,
                checkStatus,
                endDate,
                startDate,
                company,
                penalty,
            },
            signal: requestAbortController.current.signal,
        });
    }, [buildingId, trainerSectorId, checkStatus, endDate, startDate, page, company, penalty]);

    const summaryRequest = useMemo(() => {
        return httpRequest<
            undefined,
            {
                liftCheckChartData: CheckElevatorSummary;
            }
        >({
            url: `/liftCheck/liftCheckChartData`,
            params: {
                buildingId,
                trainerSectorId,
                checkStatus,
                endDate,
                startDate,
                company,
                penalty,
            },
        });
    }, [buildingId, trainerSectorId, checkStatus, endDate, startDate, company, penalty]);

    useEffect(() => {
        if (!ignore) {
            requestAbortController.current.abort();
            getList();
        }
    }, [request]);

    const getList = () => {
        if (startDate && endDate) {
            setLoading(true);
            request
                .submit()
                .then((response) => {
                    setList(response.data.liftCheckSearchList);
                    setTotalCount(response.data.liftSearchVO.allLiftCnt);
                })
                .finally(() => {
                    setLoading(false);
                });
            summaryRequest.submit().then((response) => setSummary(response.data.liftCheckChartData));
        }
    };

    const statusValueByKey = useMemo(
        () => ({
            REQUEST: "접수완료",
            ING: "점검중",
            COMPLETED: "조치완료",
            CONFIRM: "결재완료",
        }),
        [],
    );

    const downloadReport = (liftId: string) => {
        window.open(axiosInstance.defaults.baseURL + `/excel/lift/${liftId}`, "_blank");
    };

    return {list, loading, refresh: getList, summary, statusValueByKey, totalCount, downloadReport};
}

function useCheckElevator(liftId?: number) {
    const [detail, setDetail] = useState<CheckElevatorResponse>();

    useEffect(() => requestDetail(), [liftId]);

    const requestDetail = () => {
        if (liftId) {
            httpRequest<CheckElevatorRequestForm, {liftDetail: CheckElevatorResponse}>({
                url: `/liftCheck/liftCheckDetail`,
                params: {
                    liftId,
                },
            })
                .submit()
                .then((res) => {
                    setDetail(res.data.liftDetail);
                });
        }
    };

    const request = (form: CheckElevatorRequestForm) => {
        return httpRequest<CheckElevatorRequestForm, undefined>({
            method: "POST",
            url: `/liftCheck/insert`,
            data: form,
        }).submit();
    };

    const update = (form: CheckElevatorUpdateForm) => {
        return httpRequest<CheckElevatorUpdateForm, undefined>({
            method: "PUT",
            url: `/liftCheck/liftCheckModify`,
            data: form,
        }).submit();
    };
    const deleteReport = (liftId: string) => {
        return httpRequest<{liftId: string}, {result: string}>({
            method: "DELETE",
            url: `/liftCheck/liftCheckDelete`,
            data: {
                liftId: liftId,
            },
        })
            .submit()
            .then((res) => {
                if (res.data.result === "SUCCESS") {
                    message.success("삭제되었습니다.");
                } else {
                    message.error("오류가 발생하였습니다.");
                }
            })
            .catch((e) => {
                console.log(e);
                message.error("오류가 발생하였습니다.");
            });
    };

    return {detail, request, update, deleteReport};
}

const useElevatorList = (buildingId: number) => {
    const [response, setResponse] = useState<{machineId: number; machineName: string}[]>([]);

    const passenger = useMemo(() => {
        return httpRequest<null, {machineId: number; machineName: string}[]>({
            method: "GET",
            url: `/common/machines?buildingId=${buildingId}&machineCategoryCode=LT_ST043`,
        });
    }, [buildingId]);
    const freight = useMemo(() => {
        return httpRequest<null, {machineId: number; machineName: string}[]>({
            method: "GET",
            url: `/common/machines?buildingId=${buildingId}&machineCategoryCode=LT_ST044`,
        });
    }, [buildingId]);

    const submit = async () => {
        try {
            const resPassenger = await passenger.submit();
            const resFreight = await freight.submit();

            setResponse([...resPassenger.data, ...resFreight.data]);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (buildingId !== 0) submit();
    }, [buildingId]);

    return {elevatorList: response};
};

export {useCheckElevator, useCheckElevatorList, useElevatorList};
