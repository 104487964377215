import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ResponsivePie} from "@nivo/pie";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import {ProgressBar} from "~/components/progress/progress-bar";
import {useCheckElevatorList} from "~/data/check/elevator/use-check-elevator";
import {httpRequest} from "~/fetch/common/http-request";
import {calculatePercentage} from "~/utills/calculatePercentage";

import styles from "./pc-dashboard.page.module.scss";

const cx = classNames.bind(styles);

const CheckStatusMember = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const {summary} = useCheckElevatorList({
        perPage: 1000,
        page: 1,
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
    });

    const navigate = useNavigate();

    useEffect(() => {
        httpRequest<any, any>({
            method: "GET",
            url: "/buildings/checks/total/list",
            params: {
                orderDate: dayjs().format("YYYY-MM-DD"),
            },
        })
            .submit()
            .then((res: any) => {
                setData(res.data[0]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <div className={cx("card", "check-status")}>
            <div className={cx("loading", {active: isLoading})}>
                <div className={cx("loading-text")}>Loading</div>
            </div>
            <div className={cx("body")}>
                <div className={cx("check-analytics")}>
                    <div className={cx("analytics-area")}>
                        <ul className={cx("analytics-list")}>
                            <li className={cx("analytics-item")}>
                                <span
                                    className={cx("analytics-item-title", "clickable")}
                                    onClick={() => navigate("/check/legal/report")}
                                >
                                    법정점검
                                </span>
                                <ProgressBar
                                    value={calculatePercentage(data.legalEndCnt, data.legalAllCnt)}
                                    width={80}
                                    valueColor={"#d9534f"}
                                    unitColor={"black"}
                                />
                                <p className={cx("label")}>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className={cx("label-icon")}
                                            color={"#d9534f"}
                                        />
                                        <span>점검완료</span>
                                        <span className={cx("label-value", "highlight")}>
                                            {Number(data.legalEndCnt).toLocaleString()}
                                        </span>
                                        <span>건</span>
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className={cx("label-icon")}
                                            color={"#a6a6a6"}
                                        />
                                        <span>전체</span>
                                        <span className={cx("label-value")}>
                                            {Number(data.legalAllCnt).toLocaleString()}
                                        </span>
                                        <span>건</span>
                                    </span>
                                </p>
                            </li>
                            <li className={cx("analytics-item")}>
                                <span
                                    className={cx("analytics-item-title", "clickable")}
                                    onClick={() => navigate("/check/checklist/report")}
                                >
                                    체크리스트
                                </span>
                                <ProgressBar
                                    value={calculatePercentage(data.checkEndCnt, data.checkAllCnt)}
                                    width={80}
                                    valueColor={"#d9534f"}
                                    unitColor={"black"}
                                />
                                <p className={cx("label")}>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className={cx("label-icon")}
                                            color={"#d9534f"}
                                        />
                                        <span>점검완료</span>
                                        <span className={cx("label-value", "highlight")}>
                                            {Number(data.checkEndCnt).toLocaleString()}
                                        </span>
                                        <span>건</span>
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className={cx("label-icon")}
                                            color={"#a6a6a6"}
                                        />
                                        <span>전체</span>
                                        <span className={cx("label-value")}>
                                            {Number(data.checkAllCnt).toLocaleString()}
                                        </span>
                                        <span>건</span>
                                    </span>
                                </p>
                            </li>
                            <li className={cx("analytics-item")}>
                                <span
                                    className={cx("analytics-item-title", "clickable")}
                                    onClick={() => navigate("/check/extra/report")}
                                >
                                    일반점검
                                </span>
                                <ProgressBar
                                    value={calculatePercentage(data.addEndCnt, data.addAllCnt)}
                                    width={80}
                                    valueColor={"#d9534f"}
                                    unitColor={"black"}
                                />
                                <p className={cx("label")}>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className={cx("label-icon")}
                                            color={"#d9534f"}
                                        />
                                        <span>점검완료</span>
                                        <span className={cx("label-value", "highlight")}>
                                            {Number(data.addEndCnt).toLocaleString()}
                                        </span>
                                        <span>건</span>
                                    </span>
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className={cx("label-icon")}
                                            color={"#a6a6a6"}
                                        />
                                        <span>전체</span>
                                        <span className={cx("label-value")}>
                                            {Number(data.addAllCnt).toLocaleString()}
                                        </span>
                                        <span>건</span>
                                    </span>
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className={cx("elevator-area")}>
                        <span className={cx("elevator-title", "clickable")} onClick={() => navigate("/check/elevator")}>
                            승강기 점검
                        </span>
                        <div className={cx("elevator-chart-wrapper")}>
                            <ResponsivePie
                                data={[
                                    {
                                        id: "완료",
                                        label: "완료",
                                        value: summary.COMPLETED || 0,
                                        color: "#D9534FFF",
                                    },
                                    {
                                        id: "접수",
                                        label: "접수",
                                        value: summary.REQUEST || 0,
                                        color: "#dcdcdc",
                                    },
                                ]}
                                margin={{top: 10, right: 0, bottom: 10, left: 0}}
                                innerRadius={0.75}
                                padAngle={2}
                                activeOuterRadiusOffset={8}
                                colors={(data) => data.data.color}
                                enableArcLinkLabels={false}
                                enableArcLabels={false}
                            />
                            <div className={cx("elevator-chart-text")}>
                                <p>
                                    접수 <span className={cx("elevator-chart-value")}>{summary.REQUEST}</span> 건
                                </p>
                                <p>
                                    완료 <span className={cx("elevator-chart-value")}>{summary.COMPLETED}</span> 건
                                </p>
                                <span className={cx("elevator-chart-penalty")}>
                                    패널티 <span className={cx("elevator-chart-value")}>{summary.penalty_above}</span>{" "}
                                    건
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {CheckStatusMember};
