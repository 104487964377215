import React, {useCallback, useEffect, useState} from "react";
import {message, Table} from "antd";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {useFiles} from "~/data/drive/use-files";
import {AddFileRequest} from "~/data/drive/use-files.interface";
import {TargetType} from "~/data/merge/merge.interface";
import {useMergeRegisterTargetList} from "~/data/merge/register";
import {useUser} from "~/data/user";
import {httpRequest} from "~/fetch/common/http-request";
import {AddNewFileModal} from "~/pages/drive/add-new-file.modal";
import {FileDetailModal} from "~/pages/drive/file-detail.modal";
import {DriveBoardFileInputType, DriveBoardModalProps} from "~/pages/drive-board/modal/drive-board.modal.interface";
import {DriveBoardAddModal} from "~/pages/drive-board/modal/drive-board-add.modal";

import styles from "./drive-board.modal.module.scss";

const cx = classNames.bind(styles);

// const FileItem = ({data, refresh}: FileItemProps) => {
//     const [isOpen, setIsOpen] = useState<boolean>(false);
//     const [isUpdate, setIsUpdate] = useState<boolean>(false);
//     const [loading, setLoading] = useState<boolean>(false);
//     const [inputs, setInputs] = useState<DriveBoardFileInputType>({
//         title: "",
//         contents: "",
//         lockYn: "N",
//         fileBoardDataDTOList: [],
//         originFiles: [],
//     });
//
//     const {fileDownload, deleteBoardFile, updateFile, deleteFile} = usefile(data.fileBoardSeq);
//     const {user} = useUser();
//
//     const handleUpdate = async () => {
//         try {
//             setLoading(true);
//             const filesInfo: {
//                 fileOriginalName: string;
//                 filePath: string;
//                 fileBoardDataSeq: number;
//             }[] = await Promise.all(
//                 inputs.fileBoardDataDTOList.map(async (item) => {
//                     let temp: {fileOriginalName: string; filePath: string; fileBoardDataSeq: number} = {
//                         fileOriginalName: "",
//                         filePath: "",
//                         fileBoardDataSeq: 0,
//                     };
//                     await httpRequest<{uploadFile: File}, {responseData: {filePath: string; fileName: string}}>({
//                         method: "POST",
//                         url: "lotte/file/upload",
//                         headers: {"Content-Type": "multipart/form-data"},
//                         data: {uploadFile: item},
//                     })
//                         .submit()
//                         .then((res) => {
//                             temp = {
//                                 filePath: res.data.responseData.filePath,
//                                 fileOriginalName: res.data.responseData.fileName,
//                                 fileBoardDataSeq: 0,
//                             };
//                         })
//                         .catch((err) => {
//                             console.log(err);
//                         });
//                     return temp;
//                 }),
//             );
//             await updateFile({
//                 title: inputs.title,
//                 lockYn: inputs.lockYn,
//                 contents: inputs.contents,
//                 fileBoardSeq: data.fileBoardSeq,
//                 fileBoardDataDTOList: [
//                     ...filesInfo,
//                     ...inputs.originFiles!.map((item) => ({
//                         fileBoardDataSeq: item.fileBoardSeq,
//                         fileOriginalName: item.fileOriginalName,
//                         filePath: item.filePath,
//                     })),
//                 ],
//             })
//                 .submit()
//                 .then()
//                 .catch(() =>
//                     Swal.fire({
//                         text: "오류가 발생하였습니다. 다시 시도해주세요",
//                         confirmButtonText: "확인",
//                     }),
//                 );
//         } catch (e) {
//             console.log(e);
//         } finally {
//             setLoading(false);
//             refresh();
//         }
//     };
//     const handleDelete = () => {
//         Swal.fire({
//             text: "삭제하시겠습니까?",
//             showCancelButton: true,
//             confirmButtonText: "삭제하기",
//             cancelButtonText: "취소하기",
//         }).then((info) => {
//             if (info.isConfirmed) {
//                 deleteBoardFile(data.fileBoardSeq)
//                     .submit()
//                     .then(message.success("삭제되었습니다."))
//                     .catch(() => {
//                         Swal.fire({
//                             text: "오류가 발생하였습니다. 다시 시도해주세요",
//                             confirmButtonText: "확인",
//                         });
//                     })
//                     .finally(() => {
//                         setIsOpen(false);
//                         setIsUpdate(false);
//                         refresh();
//                     });
//             }
//         });
//     };
//     const handleFileDelete = ({fileName, fileBoardDataSeq}: {fileName: string; fileBoardDataSeq: number}) => {
//         Swal.fire({
//             title: "삭제하시겠습니까?",
//             text: fileName,
//             showCancelButton: true,
//             confirmButtonText: "삭제하기",
//             cancelButtonText: "취소하기",
//         }).then((info) => {
//             if (info.isConfirmed) {
//                 deleteFile(fileBoardDataSeq)
//                     .submit()
//                     .then(() => {
//                         setInputs((p) => ({
//                             ...p,
//                             originFiles: p.originFiles?.filter((item) => item.fileBoardDataSeq !== fileBoardDataSeq),
//                         }));
//                         message.success("삭제되었습니다.");
//                     })
//                     .catch(() =>
//                         Swal.fire({
//                             text: "오류가 발생하였습니다. 다시 시도해주세요",
//                             confirmButtonText: "확인",
//                         }),
//                     )
//                     .finally(() => {
//                         refresh();
//                     });
//             }
//         });
//     };
//
//     useEffect(() => {
//         setInputs({
//             title: data.title,
//             lockYn: data.lockYn,
//             contents: data.contents,
//             fileBoardDataDTOList: [],
//             originFiles: data.fileBoardDataDTOList,
//         });
//     }, [data]);
//
//     return (
//         <li className={cx("file-item")}>
//             <Spin spinning={loading}>
//                 <span
//                     className={cx("file-title")}
//                     onClick={() => {
//                         if (!isUpdate) {
//                             setIsOpen((p) => !p);
//                         }
//                     }}
//                 >
//                     <FontAwesomeIcon icon={faFile} className={cx("icon")} />
//                     {isUpdate ? (
//                         <Input
//                             className={cx("file-title-input")}
//                             placeholder={"문서 제목 수정"}
//                             value={inputs.title}
//                             onChange={(e) => setInputs((p) => ({...p, title: e.target.value}))}
//                         />
//                     ) : (
//                         data.title
//                     )}
//                     <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} className={cx("icon")} />
//                 </span>
//                 {isOpen && (
//                     <>
//                         <div className={cx("file-info")}>
//                             <span className={cx("file-lock")}>
//                                 공개여부 :{" "}
//                                 {isUpdate ? (
//                                     <Select
//                                         options={[
//                                             {label: "공개", value: "N"},
//                                             {label: "비공개", value: "Y"},
//                                         ]}
//                                         getPopupContainer={(trigger) => trigger.parentNode}
//                                         style={{width: "100px"}}
//                                         value={inputs.lockYn}
//                                         onChange={(e) => setInputs((p) => ({...p, lockYn: e}))}
//                                     />
//                                 ) : data.lockYn === "Y" ? (
//                                     "비공개"
//                                 ) : (
//                                     "공개"
//                                 )}
//                             </span>
//                             <div className={cx("files")}>
//                                 첨부파일
//                                 {isUpdate ? (
//                                     <>
//                                         <Upload
//                                             name={"file"}
//                                             multiple={true}
//                                             beforeUpload={(e: File) => {
//                                                 setInputs((prev) => {
//                                                     const newFiles = [...prev.fileBoardDataDTOList];
//                                                     newFiles.push(e);
//                                                     return {
//                                                         ...prev,
//                                                         fileBoardDataDTOList: newFiles,
//                                                     };
//                                                 });
//                                                 return false;
//                                             }}
//                                         >
//                                             <Button
//                                                 label={"파일 업로드"}
//                                                 color={"primary"}
//                                                 icon={<FontAwesomeIcon icon={faUpload} style={{marginRight: "4px"}} />}
//                                             />
//                                         </Upload>
//                                         {inputs.originFiles!.map((item, index) => (
//                                             <p className={cx("origin-files")} key={index}>
//                                                 <FontAwesomeIcon icon={faPaperclip} className={cx("icon")} />
//                                                 <span className={cx("origin-file-name")}>
//                                                     {item.fileOriginalName
//                                                         ? item.fileOriginalName.split("/").pop()
//                                                         : "손상된 파일(알 수 없음)"}
//                                                 </span>
//                                                 <FontAwesomeIcon
//                                                     icon={faTrash}
//                                                     className={cx("icon", "delete-icon")}
//                                                     onClick={() =>
//                                                         handleFileDelete({
//                                                             fileName: item.fileOriginalName
//                                                                 ? item.fileOriginalName.split("/").pop() ??
//                                                                   "손상된 파일(알 수 없음)"
//                                                                 : "손상된 파일(알 수 없음)",
//                                                             fileBoardDataSeq: item.fileBoardDataSeq,
//                                                         })
//                                                     }
//                                                 />
//                                             </p>
//                                         ))}
//                                     </>
//                                 ) : (
//                                     <ul>
//                                         {data.fileBoardDataDTOList.map((item, index) => (
//                                             <li key={index}>
//                                                 <span
//                                                     className={cx("file")}
//                                                     onClick={() => fileDownload(item.fileBoardDataSeq)}
//                                                 >
//                                                     <FontAwesomeIcon icon={faPaperclip} className={cx("file-icon")} />
//                                                     {item.fileOriginalName
//                                                         ? item.fileOriginalName.split("/").pop()
//                                                         : "손상된 파일(알 수 없음)"}
//                                                 </span>
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 )}
//                             </div>
//                             {isUpdate ? (
//                                 <TextArea
//                                     placeholder={"내용을 입력하세요"}
//                                     value={inputs.contents}
//                                     onChange={(e) => setInputs((p) => ({...p, contents: e.target.value}))}
//                                 />
//                             ) : (
//                                 <pre className={cx("file-content")}>{data.contents}</pre>
//                             )}
//                         </div>
//                         {user?.division === "MEMBER" && (
//                             <div className={cx("file-button-container")}>
//                                 <Button
//                                     label={isUpdate ? "저장하기" : "수정하기"}
//                                     color={isUpdate ? "signature" : "primary"}
//                                     onClick={
//                                         isUpdate
//                                             ? () => {
//                                                   handleUpdate();
//                                                   setIsUpdate(false);
//                                               }
//                                             : () => setIsUpdate(true)
//                                     }
//                                 />
//                                 {isUpdate && (
//                                     <Button
//                                         label={"취소하기"}
//                                         color={"primary"}
//                                         onClick={() => {
//                                             setInputs((p) => ({...p, fileBoardDataDTOList: []}));
//                                             setIsUpdate(false);
//                                         }}
//                                     />
//                                 )}
//                                 {!isUpdate && <Button label={"삭제하기"} onClick={handleDelete} />}
//                             </div>
//                         )}
//                     </>
//                 )}
//             </Spin>
//         </li>
//     );
// };

const DriveBoardModal = ({close, targetData, refresh}: DriveBoardModalProps) => {
    const [accessible, setAccessible] = useState<{targetType: TargetType; targetDTOList: number[]}>({
        targetType: "ALL",
        targetDTOList: [],
    });
    const [searchSpecific, setSearchSpecific] = useState<string>("");
    const [detailModal, setDetailModal] = useState<number | false>(false);
    const [isAdd, setIsAdd] = useState<boolean>(false);
    // const [addLoading, setAddLoading] = useState<boolean>(false);
    const [isAddInputs, setIsAddInputs] = useState<DriveBoardFileInputType>({
        title: "",
        contents: "",
        lockYn: "N",
        fileBoardDataDTOList: [],
    });
    const [updateModal, setUpdateModal] = useState<boolean>(false);

    const [submitTargetList, targetList] = useMergeRegisterTargetList();
    const {fileList, addFile, submit, loading} = useFiles(targetData.id);
    const {user} = useUser();

    const handleResetAddInputs = useCallback(() => {
        setIsAdd(false);
        setIsAddInputs({
            title: "",
            contents: "",
            lockYn: "N",
            fileBoardDataDTOList: [],
        });
    }, []);
    // const handleAdd = async () => {
    //     try {
    //         const filesInfo: {
    //             fileOriginalName: string;
    //             filePath: string;
    //         }[] = await Promise.all(
    //             isAddInputs.fileBoardDataDTOList.map(async (item) => {
    //                 let temp: {fileOriginalName: string; filePath: string} = {fileOriginalName: "", filePath: ""};
    //                 await httpRequest<{uploadFile: File}, {responseData: {filePath: string; fileName: string}}>({
    //                     method: "POST",
    //                     url: "lotte/file/upload",
    //                     headers: {"Content-Type": "multipart/form-data"},
    //                     data: {uploadFile: item},
    //                 })
    //                     .submit()
    //                     .then((res) => {
    //                         temp = {
    //                             filePath: res.data.responseData.filePath,
    //                             fileOriginalName: res.data.responseData.fileName,
    //                         };
    //                     })
    //                     .catch((err) => {
    //                         console.log(err);
    //                     });
    //                 return temp;
    //             }),
    //         );
    //
    //         await addFile({
    //             categoryId: targetData.id,
    //             title: isAddInputs.title,
    //             contents: isAddInputs.contents,
    //             lockYn: isAddInputs.lockYn,
    //             fileBoardDataDTOList: filesInfo,
    //         })
    //             .submit()
    //             .then()
    //             .catch(() =>
    //                 Swal.fire({
    //                     text: "오류가 발생하였습니다. 다시 시도해주세요",
    //                     confirmButtonText: "확인",
    //                 }),
    //             );
    //     } catch (e) {
    //         console.log(e);
    //     } finally {
    //         message.success("저장되었습니다.");
    //         submit({
    //             allRecordCnt: 0,
    //             categoryId: Number(targetData.id),
    //             pageNo: 1,
    //             recordPerPage: 50,
    //             startRecordNo: 0,
    //             includeSubfolders: false,
    //         });
    //         refresh();
    //         handleResetAddInputs();
    //     }
    // };
    const handleSave = (data: Omit<AddFileRequest, "categoryId">) => {
        addFile({
            ...data,
            categoryId: Number(targetData.id),
        })
            .submit()
            .then(() => {
                submit({
                    allRecordCnt: 0,
                    categoryId: Number(targetData.id),
                    pageNo: 1,
                    recordPerPage: 50,
                    startRecordNo: 0,
                    includeSubfolders: false,
                });
                setIsAdd(false);
            })
            .catch(() => {
                Swal.fire({
                    title: "오류가 발생했습니다.",
                    text: "문제가 지속될 경우 관리자에 문의하세요.",
                    confirmButtonText: "확인",
                });
            });
    };
    const handleDelete = async () => {
        Swal.fire({
            title: "정말 삭제하시겠습니까?",
            text: "첨부된 모든 파일이 삭제됩니다",
            showCancelButton: true,
            confirmButtonText: "삭제하기",
            cancelButtonText: "취소하기",
        })
            .then((info) => {
                if (info.isConfirmed) {
                    const loading = message.loading("삭제중입니다...", 0);
                    httpRequest<{id: string | number}, any>({
                        method: "DELETE",
                        url: "fileBoard/setDeleteFileCategory",
                        params: {
                            id: targetData.id,
                        },
                    })
                        .submit()
                        .then(() => {
                            loading();
                            message.success("삭제되었습니다.");
                            refresh();
                            close();
                        })
                        .catch((e) =>
                            Swal.fire({
                                text: "오류가 발생하였습니다. 다시 시도해주세요",
                                confirmButtonText: "확인",
                            }),
                        );
                }
            })
            .finally(() => {
                close();
            });
    };

    useEffect(() => {
        submit({
            allRecordCnt: 0,
            categoryId: Number(targetData.id),
            pageNo: 1,
            recordPerPage: 50,
            startRecordNo: 0,
            includeSubfolders: false,
        });
        setAccessible({
            targetType: targetData.targetType as TargetType,
            targetDTOList: targetData.targetId ?? [],
        });
    }, [targetData]);
    useEffect(() => {
        submitTargetList();
    }, []);

    return (
        <div
            className={cx("file-list-wrapper")}
            // onClose={close}
            // nonePrevent
            // confirmLabel={user?.division === "MEMBER" ? "추가하기" : undefined}
            // onConfirm={() => {
            //     setIsAdd(true);
            // }}
            // extraButton={
            //     <>
            //         {user?.division === "MEMBER" && <Button label={"수정하기"} onClick={() => setUpdateModal(true)} />}
            //         {user?.division === "MEMBER" && <Button label={"삭제하기"} onClick={handleDelete} />}
            //     </>
            // }
        >
            <>
                {updateModal && (
                    <DriveBoardAddModal
                        close={() => setUpdateModal(false)}
                        isShow={updateModal}
                        refresh={refresh}
                        updateClose={() => close()}
                        isUpdate={{
                            id: targetData.id,
                            title: targetData.text,
                            targetType: targetData.targetType,
                            targetDTOList: targetData.targetId,
                        }}
                    />
                )}
                {detailModal && (
                    <FileDetailModal
                        fileBoardSeq={detailModal}
                        onClose={() => setDetailModal(false)}
                        refresh={() =>
                            submit({
                                allRecordCnt: 0,
                                categoryId: Number(targetData.id),
                                pageNo: 1,
                                recordPerPage: 50,
                                startRecordNo: 0,
                                includeSubfolders: false,
                            })
                        }
                    />
                )}
                {isAdd && (
                    <AddNewFileModal
                        onSave={handleSave}
                        onClose={() => {
                            setIsAdd(false);
                        }}
                        refresh={() =>
                            submit({
                                allRecordCnt: 0,
                                categoryId: Number(targetData.id),
                                pageNo: 1,
                                recordPerPage: 50,
                                startRecordNo: 0,
                                includeSubfolders: false,
                            })
                        }
                    />
                )}
                <p className={cx("label")}>
                    [{targetData.text}] 파일 리스트
                    <div className={cx("folder-button-container")}>
                        {user?.division === "MEMBER" && (
                            <Button label={"파일 추가하기"} onClick={() => setIsAdd(true)} />
                        )}
                        {user?.division === "MEMBER" && (
                            <Button label={"수정하기"} onClick={() => setUpdateModal(true)} />
                        )}
                        {user?.division === "MEMBER" && <Button label={"삭제하기"} onClick={handleDelete} />}
                    </div>
                </p>
                <Table
                    className={cx("table")}
                    dataSource={fileList}
                    pagination={false}
                    rowKey={"fileBoardSeq"}
                    onRow={(record) => ({
                        onClick: () => setDetailModal(record.fileBoardSeq),
                        style: {
                            cursor: "pointer",
                        },
                    })}
                    columns={[
                        {
                            title: "No",
                            dataIndex: "fileBoardSeq",
                        },
                        {
                            title: "제목",
                            dataIndex: "title",
                            width: "50%",
                        },
                        {
                            title: "작성자",
                            dataIndex: "buildingName",
                        },
                        {
                            title: "등록일",
                            dataIndex: "createDate",
                        },
                    ]}
                />
                {/*<ul className={cx("file-list")}>*/}
                {/*    {fileList.map((item, index) => (*/}
                {/*        <FileItem*/}
                {/*            key={index}*/}
                {/*            data={item}*/}
                {/*            refresh={() =>*/}
                {/*                submit({*/}
                {/*                    allRecordCnt: 0,*/}
                {/*                    categoryId: Number(targetData.id),*/}
                {/*                    pageNo: 1,*/}
                {/*                    recordPerPage: 50,*/}
                {/*                    startRecordNo: 0,*/}
                {/*                    includeSubfolders: false,*/}
                {/*                })*/}
                {/*            }*/}
                {/*        />*/}
                {/*    ))}*/}
                {/*    /!* 추가하기 ---------------*!/*/}
                {/*    {isAdd && (*/}
                {/*        <li className={cx("file-item")}>*/}
                {/*            <Spin spinning={addLoading}>*/}
                {/*                <span className={cx("file-title")}>*/}
                {/*                    <FontAwesomeIcon icon={faFile} className={cx("icon")} />*/}
                {/*                    <Input*/}
                {/*                        className={cx("file-title-input")}*/}
                {/*                        placeholder={"문서 제목 입력"}*/}
                {/*                        value={isAddInputs.title}*/}
                {/*                        onChange={(e) => setIsAddInputs((p) => ({...p, title: e.target.value}))}*/}
                {/*                    />*/}
                {/*                </span>*/}
                {/*                <div className={cx("file-info")}>*/}
                {/*                    <span className={cx("file-lock")}>*/}
                {/*                        공개여부 :{" "}*/}
                {/*                        <Select*/}
                {/*                            options={[*/}
                {/*                                {label: "공개", value: "N"},*/}
                {/*                                {label: "비공개", value: "Y"},*/}
                {/*                            ]}*/}
                {/*                            getPopupContainer={(trigger) => trigger.parentNode}*/}
                {/*                            style={{width: "100px"}}*/}
                {/*                            value={isAddInputs.lockYn}*/}
                {/*                            onChange={(e) => setIsAddInputs((p) => ({...p, lockYn: e}))}*/}
                {/*                        />*/}
                {/*                    </span>*/}
                {/*                    <div className={cx("files")}>*/}
                {/*                        첨부파일*/}
                {/*                        <Upload*/}
                {/*                            name={"file"}*/}
                {/*                            multiple={true}*/}
                {/*                            beforeUpload={(e: File) => {*/}
                {/*                                setIsAddInputs((prev) => {*/}
                {/*                                    const newFiles = [...prev.fileBoardDataDTOList];*/}
                {/*                                    newFiles.push(e);*/}
                {/*                                    return {*/}
                {/*                                        ...prev,*/}
                {/*                                        fileBoardDataDTOList: newFiles,*/}
                {/*                                    };*/}
                {/*                                });*/}
                {/*                                return false;*/}
                {/*                            }}*/}
                {/*                        >*/}
                {/*                            <Button*/}
                {/*                                label={"파일 업로드"}*/}
                {/*                                color={"primary"}*/}
                {/*                                icon={<FontAwesomeIcon icon={faUpload} style={{marginRight: "4px"}} />}*/}
                {/*                            />*/}
                {/*                        </Upload>*/}
                {/*                    </div>*/}
                {/*                    <TextArea*/}
                {/*                        placeholder={"내용을 입력하세요"}*/}
                {/*                        value={isAddInputs.contents}*/}
                {/*                        onChange={(e) => setIsAddInputs((p) => ({...p, contents: e.target.value}))}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className={cx("file-button-container")}>*/}
                {/*                    <Button label={"저장하기"} color={"signature"} onClick={handleAdd} />*/}
                {/*                    <Button label={"취소하기"} color={"primary"} onClick={handleResetAddInputs} />*/}
                {/*                </div>*/}
                {/*            </Spin>*/}
                {/*        </li>*/}
                {/*    )}*/}
                {/*</ul>*/}
            </>
        </div>
    );
};

export {DriveBoardModal};
