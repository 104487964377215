import {useEffect, useState} from "react";
import {DatePicker, Input, TimePicker} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import Button from "~/components/button";
import {CenterModal} from "~/components/modal/center-modal";
import {useCalendarUpsert} from "~/data/dashboard/calendar/use-calendar-list";
import {
    DashboardCalendarModalInputType,
    DashboardCalendarModalProps,
} from "~/pages/dashboard/calendar/modal/dashboard-calendar.modal.interface";

import styles from "./dashboard-calendar.modal.module.scss";

const cx = classNames.bind(styles);

const DashboardCalendarModal = ({isShow, close, refresh, date, selectedDay, data}: DashboardCalendarModalProps) => {
    const [inputs, setInputs] = useState<DashboardCalendarModalInputType>({
        title: "",
        content: "",
        date: dayjs(),
        startTime: null,
    });

    const {create, update} = useCalendarUpsert();

    const handleConfirm = async () => {
        if (data) {
            await update({
                calendarId: data.calendarId,
                start: inputs.date.format("YYYY-MM-DD"),
                title: inputs.title,
                content: inputs.content,
                startTime: inputs.startTime?.format("HH:mm") ?? "",
            });
        } else {
            await create({
                start: inputs.date.format("YYYY-MM-DD"),
                title: inputs.title,
                content: inputs.content,
                startTime: inputs.startTime?.format("HH:mm") ?? "",
            });
        }

        setTimeout(refresh, 500);
        close();
    };

    useEffect(() => {
        if (!data) {
            setInputs((p) => ({
                ...p,
                date: selectedDay ? dayjs(selectedDay.format("YYYY-MM-DD")) : dayjs(date.format("YYYY-MM-01")),
            }));
        }
    }, [date, selectedDay]);
    useEffect(() => {
        if (data)
            setInputs({
                title: data.title,
                content: data.content,
                date: dayjs(data.start),
                startTime: dayjs(`${data.start} ${data.startTime}`),
            });
    }, [data]);

    return (
        <CenterModal
            isShow={isShow}
            close={close}
            extraButtons={[<Button label={"저장"} key={"confirm"} onClick={handleConfirm} />]}
            closeIcon={false}
            title={"일정 등록"}
        >
            <>
                <ul className={cx("input-list")}>
                    <li className={cx("input-item")}>
                        <span className={cx("input-label")}>일자</span>
                        <DatePicker
                            value={inputs.date}
                            allowClear={false}
                            onChange={(e) => setInputs((p) => ({...p, date: e}))}
                        />
                    </li>
                    <li className={cx("input-item")}>
                        <span className={cx("input-label")}>일정 내용</span>
                        <Input
                            className={cx("input")}
                            placeholder={"일정내용 입력"}
                            value={inputs.title}
                            onChange={(e) => setInputs((p) => ({...p, title: e.target.value}))}
                        />
                    </li>
                    <li className={cx("input-item")}>
                        <span className={cx("input-label")}>일정 상세</span>
                        <Input
                            className={cx("input")}
                            placeholder={"일정상세 입력"}
                            value={inputs.content}
                            onChange={(e) => setInputs((p) => ({...p, content: e.target.value}))}
                        />
                    </li>
                    <li className={cx("input-item")}>
                        <span className={cx("input-label")}>일정 시간</span>
                        <TimePicker
                            showSecond={false}
                            value={inputs.startTime}
                            onChange={(e) => setInputs((p) => ({...p, startTime: e}))}
                        />
                    </li>
                </ul>
            </>
        </CenterModal>
    );
};

export {DashboardCalendarModal};
