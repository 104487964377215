const getWeatherFormText = (weatherText: string) => {
    const getPrimaryWeather = (weather: string) => {
        const weathers = weather.split("/");
        const priority = [
            "눈",
            "눈날림",
            "비/눈",
            "빗방울/눈날림",
            "빗방울",
            "소나기",
            "비",
            "흐림",
            "구름많음",
            "구름조금",
            "맑음",
        ];

        return priority.find((item) => weathers.includes(item)) as string;
    };

    const result = getPrimaryWeather(weatherText);
    if (result === "맑음") {
        return {
            text: "맑음",
            icon: "sunny",
        };
    } else if (result === "구름조금") {
        return {
            text: "구름 조금",
            icon: "little_cloud",
        };
    } else if (result === "구름많음") {
        return {
            text: "구름 많음",
            icon: "lots_cloud",
        };
    } else if (result === "흐림") {
        return {
            text: "흐림",
            icon: "cloud",
        };
    } else if (["비", "소나기", "빗방울"].includes(result)) {
        return {
            text: "비",
            icon: "rain",
        };
    } else if (["눈", "눈날림"].includes(result)) {
        return {
            text: "눈",
            icon: "snow",
        };
    } else if (["비/눈", "빗방울/눈날림"].includes(result)) {
        return {
            text: "눈/비",
            icon: "snow_rain",
        };
    } else {
        return {
            text: "맑음",
            icon: "sunny",
        };
    }
};

export {getWeatherFormText};
