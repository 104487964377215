import {useCallback, useState} from "react";
import classNames from "classnames/bind";

import Button from "~/components/button";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {PageTitle} from "~/components/page-title";
import {useDrive} from "~/data/drive/use-drive";
import {RawCategory} from "~/data/drive/use-drive.interface";
import {useUser} from "~/data/user";
import {DriveBoardModal} from "~/pages/drive-board/modal/drive-board.modal";
import {DriveBoardAddModal} from "~/pages/drive-board/modal/drive-board-add.modal";

import styles from "./drive-board.page.module.scss";

const cx = classNames.bind(styles);

const DriveBoardPage = () => {
    const perPage = 9999;
    const [page, setPage] = useState<number>(1);
    const [modal, setModal] = useState<RawCategory | false>(false);
    const [addModal, setAddModal] = useState<boolean>(false);
    const [filter, setFilter] = useState<{fileName: string; category: string}>({
        fileName: "",
        category: "",
    });

    const {formattedCategory, rawCategories, refresh, changeParentFolderForFolder, changeParentFolderForFile} =
        useDrive();
    const {user} = useUser();

    const handleReset = useCallback(() => {
        setFilter({
            fileName: "",
            category: "",
        });
    }, []);

    return (
        <div>
            {/*{modal && <DriveBoardModal targetData={modal} close={() => setModal(false)} refresh={refresh} />}*/}
            {addModal && <DriveBoardAddModal isShow={addModal} close={() => setAddModal(false)} refresh={refresh} />}
            <PageTitle>자료실</PageTitle>
            {/*<FilterArea>*/}
            {/*    <div className={cx("filter-inner")}>*/}
            {/*        <CnInput label={"파일명"} placeholder={"파일명 검색"} />*/}
            {/*        <div className={cx("button-container")}>*/}
            {/*            <Button label={"조회"} color={"primary"} onClick={() => refresh()} />*/}
            {/*            <Button label={"검색조건 초기화"} onClick={handleReset} />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</FilterArea>*/}
            <div className={cx("table-container")}>
                <CustomLabelTable
                    label={
                        <div className={cx("table-label")}>
                            <span>
                                카테고리
                                {/*{rawCategories.length} 건 ({page}/{Math.ceil(rawCategories.length / perPage)} 페이지)*/}
                            </span>
                            {user?.division === "MEMBER" && (
                                <Button label={"카테고리 추가"} onClick={() => setAddModal(true)} />
                            )}
                        </div>
                    }
                    page={page}
                    perPage={perPage}
                    noPagination
                    onPageChange={setPage}
                    totalCount={rawCategories.length}
                    dataSource={rawCategories}
                    pagination={false}
                    rowKey={"id"}
                    onRow={(record) => ({
                        onClick: () => setModal(record),
                        style: {
                            cursor: "pointer",
                        },
                    })}
                    columns={[
                        {
                            title: "No",
                            align: "center",
                            render: (_, __, index) => index + 1,
                        },
                        {
                            title: "카테고리",
                            dataIndex: "text",
                            align: "center",
                        },
                    ]}
                />
                <div className={cx("file-list-container")}>
                    {modal ? (
                        <DriveBoardModal close={() => setModal(false)} refresh={refresh} targetData={modal} />
                    ) : (
                        "카테고리를 선택하세요"
                    )}
                </div>
            </div>
        </div>
    );
};

export {DriveBoardPage};
