import {useEffect, useState} from "react";

import {WeatherResponse} from "~/data/dashboard/weather/use-weather.interface";
import {httpRequest} from "~/fetch/common/http-request";

const useWeekWeather = () => {
    const [response, setResponse] = useState<WeatherResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const submit = async () => {
        return await httpRequest<null, WeatherResponse[]>({
            method: "GET",
            url: "/common/weatherInfo",
        })
            .submit()
            .then((res) => setResponse(res.data))
            .catch();
    };

    useEffect(() => {
        submit();
    }, []);

    return {weekWeather: response, weekWeatherLoading: loading};
};

export {useWeekWeather};
