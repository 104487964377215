import React, {Fragment, useEffect, useState} from "react";
import {faDownload, faPaperclip, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as ButtonAntd, Input, List, Radio} from "antd";
import {UploadChangeParam} from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {MergeGridItem} from "~/components/merge/merge-grid-item";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title";
import {usefile} from "~/data/drive/use-files";
import {useUser} from "~/data/user";
import {singleFileUpload} from "~/fetch/single-file-upload";
import {FileDetailInterface} from "~/pages/drive/file-detail.interface";

import styles from "./file-detail.modal.module.scss";
import stylesGrid from "~/components/merge/merge-grid-item/merge-grid-item.module.scss";

const cx = classNames.bind(styles);
const gridCx = classNames.bind(stylesGrid);

const FileDetailModal = (props: FileDetailInterface) => {
    const {fileBoardSeq, onClose, refresh} = props;
    const {data, reload, updateFile, fileDownload, deleteFile, deleteBoardFile} = usefile(fileBoardSeq);
    const [isModify, setIsModify] = useState<boolean>(false);
    const [files, setFiles] = useState<
        Array<{
            fileBoardDataSeq: number;
            fileOriginalName: string;
            filePath: string;
        }>
    >([]);
    const [title, setTitle] = useState<string>("");
    const [contents, setContents] = useState<string>("");
    const [lockYn, setLockYn] = useState<"Y" | "N">("N");

    useEffect(() => {
        if (data) {
            setTitle(data.title);
            setContents(data.contents);
            setLockYn(data.lockYn);
            setFiles([
                ...data.fileBoardDataDTOList.map((file) => {
                    return {
                        fileBoardDataSeq: file.fileBoardDataSeq,
                        fileOriginalName: file.fileOriginalName,
                        filePath: file.filePath,
                    };
                }),
            ]);
        }
    }, [data]);

    const handleDelete = () => {
        Swal.fire({
            html: "게시물을 삭제하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
        }).then(async (info) => {
            if (info.isConfirmed) {
                deleteBoardFile(fileBoardSeq)
                    .submit()
                    .then(() => {
                        onClose();
                    })
                    .catch(() => {
                        Swal.fire({
                            text: "오류가 발생하였습니다. 다시 시도해주세요",
                            confirmButtonText: "확인",
                        });
                    })
                    .finally(() => {
                        refresh();
                    });
            }
        });
    };

    const handleFileChange = (param: UploadChangeParam) => {
        if (param.file.status === "done") {
            setFiles((prevFiles) => {
                return [
                    ...prevFiles,
                    {
                        fileBoardDataSeq: 0,
                        fileOriginalName: param.file.name,
                        filePath: param.file.response.filePath,
                    },
                ];
            });
        }
        if (param.file.status === "removed") {
            setFiles((prevFiles) => {
                return prevFiles.filter((f) => f.filePath !== param.file.response.filePath);
            });
        }
    };

    const removeFile = async (file: {fileBoardDataSeq: number; fileOriginalName: string; filePath: string}) => {
        try {
            if (file.fileBoardDataSeq !== 0) await deleteFile(file.fileBoardDataSeq).submit();

            setFiles((prevFiles) => {
                return prevFiles.filter((f) => f.filePath !== file.filePath);
            });
        } catch (e) {
            console.log(e);
        } finally {
            reload();
        }
    };

    const handleSave = () => {
        updateFile({
            contents: contents,
            title: title,
            fileBoardDataDTOList: files,
            lockYn: lockYn,
            fileBoardSeq: fileBoardSeq,
        })
            .submit()
            .then((res) => {
                setIsModify(false);
            })
            .catch((err) => {
                Swal.fire({
                    title: "오류가 발생했습니다.",
                    text: "문제가 지속될 경우 관리자에 문의하세요.",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => {
                refresh();
                reload();
            });
    };

    const {user} = useUser();

    return (
        <SideModal
            onClose={onClose}
            extraButton={
                isModify ? (
                    <Button label={"저장하기"} onClick={handleSave} />
                ) : (
                    <Fragment>
                        {user?.division === "MEMBER" && <Button label={"삭제하기"} onClick={handleDelete} />}
                        {user?.division === "MEMBER" && (
                            <Button label={"수정하기"} color={"primary"} onClick={() => setIsModify(true)} />
                        )}
                    </Fragment>
                )
            }
        >
            <PageTitle>파일{isModify ? "수정" : "상세"}</PageTitle>
            <div className={cx("container")}>
                <MergeGridItem name={"제목"} span>
                    {!isModify && <h4>{title}</h4>}
                    {isModify && (
                        <Input
                            className={cx("input")}
                            value={title}
                            defaultValue={title}
                            onChange={(e) => setTitle(e.target.value)}
                            disabled={!isModify}
                        />
                    )}
                </MergeGridItem>
                <MergeGridItem name={"공개여부"} span>
                    {!isModify && <p>{lockYn === "Y" ? "비공개" : "공개"}</p>}
                    {isModify && (
                        <Radio.Group
                            key={lockYn}
                            defaultValue={lockYn}
                            onChange={(e) => {
                                setLockYn(e.target.value);
                            }}
                            disabled={!isModify}
                        >
                            <Radio value="N">공개</Radio>
                            <Radio value="Y">비공개</Radio>
                        </Radio.Group>
                    )}
                </MergeGridItem>
                <MergeGridItem name={"첨부파일"} span extraClassName={"upload-container"}>
                    <div className={gridCx("file-container")}>
                        {isModify ? (
                            <Dragger
                                className={gridCx("upload-dragger")}
                                key={JSON.stringify(files)}
                                customRequest={singleFileUpload}
                                multiple
                                onChange={handleFileChange}
                                defaultFileList={files.map((file) => ({
                                    uid: file.filePath,
                                    name: file.fileOriginalName,
                                    fileName: file.fileOriginalName,
                                    filePath: file.filePath,
                                    response: {
                                        filePath: file.filePath,
                                    },
                                }))}
                                showUploadList={false}
                            >
                                {files.length ? (
                                    <List
                                        itemLayout={"horizontal"}
                                        dataSource={files}
                                        className={gridCx("file-list")}
                                        renderItem={(file, index) => (
                                            <List.Item
                                                className={gridCx("file-list-item")}
                                                key={index}
                                                actions={[
                                                    // <ButtonAntd
                                                    //     type="link"
                                                    //     key={index}
                                                    //     icon={<FontAwesomeIcon icon={faDownload} />}
                                                    //     onClick={(e) => {
                                                    //         e.stopPropagation();
                                                    //         // fileDownload(file.fileBoardDataSeq);
                                                    //     }}
                                                    // />,
                                                    <ButtonAntd
                                                        type="link"
                                                        danger
                                                        key={index}
                                                        icon={<FontAwesomeIcon icon={faTrashCan} />}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            removeFile(file);
                                                        }}
                                                    />,
                                                ]}
                                            >
                                                <List.Item.Meta
                                                    avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                                    title={
                                                        file.fileOriginalName
                                                            ? file.fileOriginalName.split("/").pop()
                                                            : "(알 수 없음)"
                                                    }
                                                    className={gridCx("file-list-item-meta")}
                                                    style={{textAlign: "left"}}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                ) : (
                                    <>
                                        <p className="ant-upload-text">
                                            파일을 끌어다 놓거나 클릭하여 파일을 선택하세요.
                                        </p>
                                        <p className="ant-upload-hint">각 파일의 용량 제한은 500MB입니다.</p>
                                        <p className="ant-upload-hint">
                                            용량이 초과될 경우 분할 압축을 하여 업로드 해주십시오.
                                        </p>
                                    </>
                                )}
                            </Dragger>
                        ) : (
                            <List
                                itemLayout="horizontal"
                                className={gridCx("file-list")}
                                dataSource={files}
                                renderItem={(file, index) => {
                                    const btns = [];

                                    if (user?.division === "MEMBER")
                                        btns.push(
                                            <ButtonAntd
                                                type="link"
                                                danger
                                                key={index}
                                                icon={<FontAwesomeIcon icon={faTrashCan} />}
                                                onClick={async (e) => {
                                                    e.stopPropagation();
                                                    Swal.fire({
                                                        html: "파일을 삭제하시겠습니까? <br/> 파일삭제는 즉시 저장됩니다",
                                                        showCancelButton: true,
                                                        confirmButtonText: "확인",
                                                        cancelButtonText: "취소",
                                                    }).then(async (info) => {
                                                        if (info.isConfirmed) {
                                                            removeFile(file);
                                                        }
                                                    });
                                                }}
                                            />,
                                        );

                                    if (file.fileBoardDataSeq !== 0)
                                        btns.unshift(
                                            <ButtonAntd
                                                type="link"
                                                key={index}
                                                icon={<FontAwesomeIcon icon={faDownload} />}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    fileDownload(file.fileBoardDataSeq);
                                                }}
                                            />,
                                        );
                                    return (
                                        <List.Item key={index} actions={btns} className={gridCx("file-list-item")}>
                                            <List.Item.Meta
                                                avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                                className={gridCx("file-list-item-meta")}
                                                title={
                                                    file.fileOriginalName
                                                        ? file.fileOriginalName.split("/").pop()
                                                        : "(알 수 없음)"
                                                }
                                                style={{textAlign: "left"}}
                                            />
                                        </List.Item>
                                    );
                                }}
                            />
                        )}
                    </div>
                </MergeGridItem>
                <MergeGridItem name={"내용"} span extraClassName={"padding-container"}>
                    {!isModify && <pre className={cx("content")}>{data?.contents}</pre>}
                    {isModify && (
                        <Input.TextArea
                            value={contents}
                            defaultValue={contents}
                            onChange={(e) => setContents(e.target.value)}
                            disabled={!isModify}
                            className={cx("text-area")}
                        />
                    )}
                </MergeGridItem>
            </div>
        </SideModal>
    );
};

export {FileDetailModal};
