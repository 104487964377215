import {useEffect, useState} from "react";
import classNames from "classnames/bind";

import {ProgressBarProps} from "~/components/progress/progress-bar.interface";

import styles from "./progress-bar.module.scss";

const cx = classNames.bind(styles);

const ProgressBar = (props: ProgressBarProps) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (!isNaN(props.value)) setValue(props.value);
    }, [props.value]);

    return (
        <div
            className={cx("wrapper")}
            style={{
                width: `${props.width}%`,
                height: `${props.height}%`,
            }}
        >
            <div
                className={cx("progress")}
                style={{
                    background: `linear-gradient(to right, ${props.progressColor || "#d9534f"} ${value}%, ${props.backgroundColor || "#ececec"} 0%)`,
                }}
            />
            <span className={cx("percent")}>
                <span style={{color: props.valueColor}}>{value}</span>
                <span style={{color: props.unitColor}}>%</span>
            </span>
        </div>
    );
};

export {ProgressBar};
