import {useMemo, useState} from "react";
import {message} from "antd";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import {
    CalendarDTOListType,
    CalendarInsertRequest,
    CalendarListRequest,
    CalendarListResponse,
    CalendarUpdateRequest,
} from "~/data/dashboard/calendar/use-calendar-list.interface";
import {httpRequest} from "~/fetch/common/http-request";

const useCalendarList = ({year, month, day}: CalendarListRequest) => {
    const [response, setResponse] = useState<CalendarDTOListType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<CalendarListRequest, CalendarListResponse>({
            method: "GET",
            url: "/calendar/l",
            params: {
                year,
                month,
                day,
            },
        });
    }, [year, month, day]);

    const submit = async () => {
        try {
            setLoading(true);
            const res = await request.submit();
            setResponse(
                res.data.CalendarDTOList.map((data) => ({
                    ...data,
                    start: dayjs(data.start).format("YYYYMMD"),
                })),
            );
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    return {scheduleList: response, submitScheduleList: submit, loading};
};

const useCalendarUpsert = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const create = async (config: CalendarInsertRequest) => {
        setLoading(true);
        return await httpRequest<CalendarInsertRequest, null>({
            method: "POST",
            url: "/calendar/c",
            data: {
                title: config.title,
                content: config.content,
                start: config.start,
                startTime: config.startTime,
            },
        })
            .submit()
            .then(() => {
                message.success("등록완료");
            })
            .catch(() => {
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setLoading(false));
    };

    const update = async (config: CalendarUpdateRequest) => {
        setLoading(true);
        return await httpRequest<CalendarUpdateRequest, null>({
            method: "PUT",
            url: "/calendar/u",
            data: config,
        })
            .submit()
            .then(() => {
                message.success("등록완료");
            })
            .catch(() => {
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setLoading(false));
    };

    const deleteList = async (id: number) => {
        setLoading(true);
        return await httpRequest<{calendarId: number}, null>({
            method: "DELETE",
            url: `/calendar/d`,
            data: {calendarId: id},
        })
            .submit()
            .then(() => message.success("삭제완료"))
            .catch(() => {
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setLoading(false));
    };

    return {create, update, deleteList, upsertLoading: loading};
};

export {useCalendarList, useCalendarUpsert};
